import * as types from "../actionTypes";

const initialState = {
  rolesDataObj: {},
  rolesDataError: false,
  rolesDataLoading: true
};

const RolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_ROLES_STARTED:
      return {
        ...state,
        rolesDataError: false,
        rolesDataLoading: true
      };
    case types.FETCHING_ROLES_SUCCESS:
      return {
        ...state,
        rolesDataObj: action.payload,
        rolesDataLoading: false
      };
    case types.FETCHING_ROLES_FAIL:
      return {
        ...state,
        rolesDataError: false,
        rolesDataLoading: false
      };
    default:
      return state;
  }
};

export default RolesReducer;
