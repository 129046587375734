import * as types from "../actionTypes";
import CompaniesApi from "../../api/companiesApi";
import { openNotifier } from "./notificationActions";

export const getCompaniesData = (page, dispatch) => {
  dispatch({ type: types.FETCHING_COMPANIES_STARTED });

  CompaniesApi.getCompanies(page)
    .then(response => dispatch({ type: types.FETCHING_COMPANIES_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_COMPANIES_FAIL }));
};

export const addCompany = (formData, companiesDataObj, closeForm, setSubmitting, dispatch) => {
  CompaniesApi.addCompany(formData)
    .then(response => {
      companiesDataObj?.data?.push(response.data);

      dispatch({ type: types.FETCHING_COMPANIES_SUCCESS, payload: companiesDataObj });

      closeForm();
      openNotifier("success", "Company has been added!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `Company wasn't added. ${error}`, dispatch);
    });
};

export const updateCompany = (formData, updatedCompaniesDataObj, closeForm, setSubmitting, dispatch) => {
  CompaniesApi.updateCompany(formData)
    .then(() => {
      dispatch({ type: types.FETCHING_COMPANIES_SUCCESS, payload: updatedCompaniesDataObj });

      closeForm();
      openNotifier("success", "Company has been updated!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `Company wasn't updated. ${error}`, dispatch);
    });
};

export const deleteCompany = (companyId, companiesDataObj, closeForm, setSubmitting, dispatch) => {
  CompaniesApi.deleteCompany(companyId)
    .then(() => {
      getCompaniesData(companiesDataObj.current_page, dispatch);
      closeForm();
      openNotifier("warning", "Company has been deleted!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `Company wasn't deleted. ${error}`, dispatch);
    });
};
