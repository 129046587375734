// list of env users
const envUsers = [
  { prefix: "dev", name: "akovalenko", server: "akovalenko.hetdev1" },
  { prefix: "dev", name: "mnagorna", server: "mnagorna.hetdev1" },
  { prefix: "dev", name: "rbuzukov", server: "rbuzukov.hetdev1" },
  { prefix: "dev", name: "release_1", server: "release.hetdev1" },
  { prefix: "dev", name: "release_2", server: "release.hetdev2" },
  { prefix: "dev", name: "skrupnyk", server: "skrupnyk.hetdev1" },
  { prefix: "dev", name: "testing_1", server: "testing.hetdev1" },
  { prefix: "dev", name: "testing_2", server: "testing.hetdev2" },
  { prefix: "dev", name: "zkryvorot", server: "zkryvorot.hetdev1" },
  { prefix: "prod", name: "live_1", server: "live.hetprod1" },
  { prefix: "prod", name: "live_2", server: "live.hetprod2" },
];

// list of dev testing projects
const envProjects = [
  {
    envSuffix: "_my-backend",
    hostPrefix: "my-backend.lenovo.ua.",
    baseDomainPrefix: "lenovo.ua.",
  },
];

// generate hosts for dev testing user
const generateUserHosts = user => {
  let userHosts = {};

  envProjects.forEach(({ hostPrefix, envSuffix }) => {
    userHosts = {
      ...userHosts,
      [`${hostPrefix}${user.server}.applemint.eu`]: `${user.prefix}_${user.name}${envSuffix}`,
    };
  });

  return userHosts;
};

// generate hosts for all dev testing users
const generateAllUserHosts = () => {
  let allUsersHosts = {};
  envUsers.forEach(user => (allUsersHosts = { ...allUsersHosts, ...generateUserHosts(user) }));
  return allUsersHosts;
};

// generate env Starters for testing user
const generateUserStarters = user => {
  let userStarters = {};

  envProjects.forEach(({ envSuffix, baseDomainPrefix }) => {
    userStarters = {
      ...userStarters,
      [`${user.prefix}_${user.name}${envSuffix}`]: {
        baseDomain: `${baseDomainPrefix}${user.server}.applemint.eu`,
      },
    };
  });

  return userStarters;
};

// generate env Starters for all testing users
const generateAllUserStarters = () => {
  let allUsersStarters = {};
  envUsers.forEach(user => (allUsersStarters = { ...allUsersStarters, ...generateUserStarters(user) }));
  return allUsersStarters;
};

const hostEnvMap = {
  localhost: "local",
  "my-backend.lenovo.ua.testing.hetdev1.applemint.eu": "dev",
  "my-backend.lenovo.ua": "prod",
  ...generateAllUserHosts(),
};

// noinspection SpellCheckingInspection
const envStarter = {
  local: { baseDomain: "lenovo.ua.testing.hetdev1.applemint.eu" },
  dev: { baseDomain: "lenovo.ua.testing.hetdev1.applemint.eu" },
  prod: { baseDomain: "lenovo.ua" },
  default: { baseDomain: "lenovo.ua.testing.hetdev1.applemint.eu" },
  ...generateAllUserStarters(),
};

// noinspection SpellCheckingInspection
const commonEnvSettings = { cookie: "lenovo_global_user", token: "lenovo_jwt_token" };

const generateEnv = () => {
  let finalEnv = {};

  for (let envName in envStarter) {
    const { baseDomain, ...envStarterWithoutBaseDomain } = envStarter[envName];

    const isLocal = envName.includes("local");
    const isProduction = envName.includes("prod");
    const cookieDomain = isLocal ? "" : "." + baseDomain;
    const profileApi = "https://my-api." + baseDomain + "/api/v1";
    const serviceApi = "https://service-api." + baseDomain;

    finalEnv[envName] = {
      isProduction,
      cookieDomain,
      ...commonEnvSettings,
      ...envStarterWithoutBaseDomain,
      ...hostEnvMap[envName],
      profileApi,
      serviceApi,
    };
  }

  return finalEnv;
};

const env = generateEnv();

function getEnv() {
  return process.env.REACT_APP_ENV ?? hostEnvMap[window.location.hostname] ?? "default";
}

export const Environment = env[getEnv()] ?? env.default;
