import React from "react";
import PropTypes from "prop-types";

// Components
import UserEditForm from "./components/UserEditForm";

// Actions
import { updateUser, deleteUser } from "../../store/actions/usersActions";

export default function UserEdit({ currentUser, usersDataObj, rolesDataObj, companiesDataObj, handleClose, dispatch }) {
  return (
    <UserEditForm
      currentUser={currentUser}
      usersDataObj={usersDataObj}
      rolesDataObj={rolesDataObj}
      companiesDataObj={companiesDataObj}
      handleClose={handleClose}
      dispatch={dispatch}
      updateUser={updateUser}
      deleteUser={deleteUser}
    />
  );
}

UserEdit.propTypes = {
  currentUser: PropTypes.object.isRequired,
  usersDataObj: PropTypes.object.isRequired,
  rolesDataObj: PropTypes.object.isRequired,
  companiesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};
