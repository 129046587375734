const regexRules = {
  latin: /^([A-Za-z_]){2,}$/,
  alpha: /^([A-Za-zА-ЯҐЄІЇа-яґєії\s]){2,}$/,
  alpha_numeric: /^([A-Za-zА-ЯҐЄІЇа-яґєії0-9\s]){10,}$/,
  phone: /^\+\d{12}$/,
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  previousHistoryLocationPathname: /(?<=\/)(.*?)(?=\/)/,
};
export default regexRules;
