import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import Loader from "../../components/Loader";
import TabPanel from "../../components/TabPanel";
import UserInfo from "./components/UserInfo";
import UserEdit from "./UserEdit";
import UserEditAdditional from "./components/UserEditAdditional";

// Actions
import { getUser } from "../../store/actions/usersActions";
import { fetchRoles } from "../../store/actions/rolesActions";
import { getCompaniesData } from "../../store/actions/companiesActions";

// Services
import { ValidationService } from "../../js/validationService";
const validationService = new ValidationService();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function UserItem() {
  const history = useHistory();
  const dispatch = useDispatch();
  const usersDataObj = useSelector(state => state.users.usersDataObj);
  const currentUserData = useSelector(state => state.users.currentUserData);
  const currentUserError = useSelector(state => state.users.currentUserError);
  const currentUserDataLoading = useSelector(state => state.users.currentUserDataLoading);
  const companiesDataObj = useSelector(state => state.companies.companiesDataObj);
  const rolesDataObj = useSelector(state => state.roles.rolesDataObj);
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const userUuid = useParams().userUuid;

  useEffect(() => userUuid !== currentUserData.uuid && getUser(userUuid, usersDataObj, dispatch), []);

  useEffect(() => !Object.keys(rolesDataObj).length && fetchRoles(1, dispatch), []);

  useEffect(() => !Object.keys(companiesDataObj).length && getCompaniesData(1, dispatch), []);

  const handleChange = (event, newValue) => setValue(newValue);

  const handleClose = () => {
    const previousLocation = validationService.getPreviousHistoryLocationPathname(history.location.pathname)[0];

    history.push(`/${previousLocation}`);
  };

  return (
    <>
      {currentUserDataLoading ? (
        <Loader isSection={true} />
      ) : currentUserError ? (
        "The current user didn't found"
      ) : (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="auto tabs"
            >
              <Tab label="User info" />
              <Tab label="Edit User" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <UserInfo currentUser={currentUserData} handleClose={handleClose} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <UserEdit
              currentUser={currentUserData}
              usersDataObj={usersDataObj}
              rolesDataObj={rolesDataObj}
              companiesDataObj={companiesDataObj}
              handleClose={handleClose}
              dispatch={dispatch}
            />

            <UserEditAdditional currentUser={currentUserData} usersDataObj={usersDataObj} dispatch={dispatch} />
          </TabPanel>
        </div>
      )}
    </>
  );
}
