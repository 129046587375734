import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";

// Components
import { SimpleInput, ButtonWithLoader } from "../../../components/formElements";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import TextFieldsIcon from "@material-ui/icons/TextFields";

const Form = props => {
  const { values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, handleClose } = props;

  return (
    <form onSubmit={handleSubmit} className="form">
      <p className="form-title">Add new Role</p>

      <div className="form-block">
        <div className="form-item">
          <div className="__with-icon">
            <PermIdentityIcon />
            <SimpleInput
              name="name"
              label="Role name"
              onBlur={handleBlur}
              value={values.name}
              onChange={handleChange}
              error={errors.name}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <TextFieldsIcon />
            <SimpleInput
              name="slug"
              label="Role slug"
              onBlur={handleBlur}
              value={values.slug}
              onChange={handleChange}
              error={errors.slug}
            />
          </div>
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader text="Add" type="submit" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

const RoleAddForm = withFormik({
  mapPropsToValues: e => {
    return {
      name: "",
      slug: "",
      rolesDataObj: e.rolesDataObj,
      handleClose: e.handleClose,
      addRole: e.addRole,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};

    if (!values.name) {
      errors.name = "Field is Required";
    }

    if (!values.slug) {
      errors.slug = "Field is Required";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    // Data constructor
    const data = { name: values.name, slug: values.slug };

    const successCallback = () => {
      setSubmitting(false);
      values.handleClose();
    };

    const failCallback = () => setSubmitting(false);

    values.addRole(data, values.rolesDataObj, successCallback, failCallback, values.dispatch);
  },
})(Form);

RoleAddForm.propTypes = {
  rolesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RoleAddForm;
