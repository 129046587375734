import * as types from "../actionTypes";
import ServiceCentersApi from "../../api/serviceCentersApi";
import { openNotifier } from "./notificationActions";

export const fetchServiceCenters = (filters, dispatch) => {
  dispatch({ type: types.FETCHING_SERVICE_CENTERS_STARTED });

  ServiceCentersApi.getServiceCenters(filters)
    .then(response => dispatch({ type: types.FETCHING_SERVICE_CENTERS_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_SERVICE_CENTERS_FAIL }));
};

export const changeServiceCentersFilters = (filter, dispatch) =>
  dispatch({ type: types.SERVICE_CENTERS_FILTERS_CHANGED, payload: filter });

export const fetchServiceCenter = (id, dispatch) => {
  dispatch({ type: types.FETCHING_CURRENT_SERVICE_CENTER_STARTED });

  ServiceCentersApi.getServiceCenter(id)
    .then(response => dispatch({ type: types.FETCHING_CURRENT_SERVICE_CENTER_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_CURRENT_SERVICE_CENTER_FAIL }));
};

export const addServiceCenter = (formData, serviceCentersData, closeForm, setSubmitting, dispatch) => {
  ServiceCentersApi.createServiceCenter(formData)
    .then(response => {
      const updatedServiceCentersData = [...serviceCentersData];
      updatedServiceCentersData.push(response.data);

      dispatch({ type: types.FETCHING_SERVICE_CENTERS_SUCCESS, payload: updatedServiceCentersData });

      closeForm();
      openNotifier("success", "Service center has been added!", dispatch);
    })
    .catch(error => {
      setSubmitting();
      openNotifier("error", `Service center wasn't added. ${error}`, dispatch);
    });
};

export const updateServiceCenter = (formData, serviceCentersData, closeForm, setSubmitting, dispatch) => {
  ServiceCentersApi.updateServiceCenter(formData)
    .then(response => {
      if (serviceCentersData && Object.keys(serviceCentersData).length) {
        const currentUserIndex = serviceCentersData.findIndex(user => user.id === formData.id);

        if (currentUserIndex >= 0) {
          const updatedUsersDataObj = JSON.parse(JSON.stringify(serviceCentersData));

          updatedUsersDataObj[currentUserIndex] = {
            ...updatedUsersDataObj[currentUserIndex],
            ...response.data,
          };

          dispatch({ type: types.SERVICE_CENTERS_UPDATED, payload: updatedUsersDataObj });
        }
      }

      dispatch({ type: types.CURRENT_SERVICE_CENTER_UPDATED, payload: response.data });

      closeForm();
      openNotifier("success", "Service center has been updated!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `Service center wasn't updated. ${error}`, dispatch);
    });
};

export const deleteServiceCenter = (id, serviceCentersFilters, closeForm, setSubmitting, dispatch) => {
  ServiceCentersApi.deleteServiceCenter(id)
    .then(() => {
      fetchServiceCenters(serviceCentersFilters, dispatch);
      closeForm();
      openNotifier("warning", "Service center has been deleted!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `Service center wasn't deleted. ${error}`, dispatch);
    });
};
