import * as types from "../actionTypes";

const initialState = {
  companiesDataObj: {},
  companiesDataError: false,
  companiesDataLoading: true,
};

const CompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_COMPANIES_STARTED:
      return {
        ...state,
        companiesDataError: false,
        companiesDataLoading: true,
      };
    case types.FETCHING_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesDataObj: action.payload,
        companiesDataLoading: false,
      };
    case types.FETCHING_COMPANIES_FAIL:
      return {
        ...state,
        companiesDataError: true,
        companiesDataLoading: false,
      };
    default:
      return state;
  }
};

export default CompaniesReducer;
