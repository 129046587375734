import React from "react";
import PropTypes from "prop-types";

import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DataPicker = ({ name, value, label, onChange }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        name={name}
        label={label}
        inputVariant="outlined"
        value={value === "null-null-null" ? null : value}
        disableFuture
        openTo="year"
        format="yyyy - MM - dd"
        views={["year", "month", "date"]}
        onChange={onChange}
        cancelLabel="Cancel"
        okLabel="Save"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );
};

DataPicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default DataPicker;
