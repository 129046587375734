import React from "react";
import PropTypes from "prop-types";

// Material UI
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const IconicBool = ({ children = "" }) => {
  if (typeof children === "boolean") {
    return children ? <CheckIcon /> : <CloseIcon />;
  }
  return children;
};

IconicBool.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};

export default IconicBool;
