import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

// Material UI
import { MuiThemeProvider } from "@material-ui/core/styles";

// Components
import theme from "./components/Theme";
import Loader from "./components/Loader";
import Notifier from "./components/SnackBarNotifier/SnackBarNotifier";

// Reducers
import { initializeState } from "./store/actions/loginActions";

import Routes from "./Routes";

export default function App() {
  const dispatch = useDispatch();
  const isAppLoading = useSelector(state => state.login.isAppLoading);
  const isUserAuthorized = useSelector(state => state.login.isUserAuthorized);
  const notifierIsOpen = useSelector(state => state.notifications.notifierIsOpen);
  const notifierVariant = useSelector(state => state.notifications.notifierVariant);
  const notifierMessage = useSelector(state => state.notifications.notifierMessage);

  useEffect(() => initializeState(dispatch), []);

  return (
    <MuiThemeProvider theme={theme}>
      {isAppLoading ? (
        <Loader isSection={true} />
      ) : (
        <>
          <Router>
            <Routes isUserAuthorized={isUserAuthorized} />
          </Router>

          <Notifier notifierIsOpenState={notifierIsOpen} variant={notifierVariant} message={notifierMessage} />
        </>
      )}
    </MuiThemeProvider>
  );
}
