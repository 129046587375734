import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    padding: "100px",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
}));

function ListItemLink(props) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <NavLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const SideMenu = () => {
  const classes = useStyles();

  const menuItems = [
    {
      title: "Roles",
      link: "/roles",
      icon: <PersonIcon />,
    },
    {
      title: "Companies",
      link: "/companies",
      icon: <BusinessIcon />,
    },
    {
      title: "Users",
      link: "/users",
      icon: <PeopleIcon />,
    },
    {
      title: "Auth Logs",
      link: "/auth_logs",
      icon: <VpnKeyIcon />,
    },
    {
      title: "Service centers",
      link: "/service-centers",
      icon: <LocationOnIcon />,
    },
  ];

  return (
    <Drawer
      className={`${classes.drawer} main-menu`}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {menuItems.map((menuItem, index) => (
            <ListItemLink key={index} to={menuItem.link} primary={menuItem.title} icon={menuItem.icon} />
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default SideMenu;
