import React from "react";
import PropTypes from "prop-types";

// Components
import CompanyEditForm from "./components/CompanyEditForm";

// Actions
import { updateCompany, deleteCompany } from "../../store/actions/companiesActions";

export default function CompanyEdit({ currentCompany, companiesDataObj, handleClose, dispatch }) {
  return (
    <CompanyEditForm
      currentCompany={currentCompany}
      companiesDataObj={companiesDataObj}
      handleClose={handleClose}
      dispatch={dispatch}
      updateCompany={updateCompany}
      deleteCompany={deleteCompany}
    />
  );
}

CompanyEdit.propTypes = {
  currentCompany: PropTypes.object.isRequired,
  companiesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};
