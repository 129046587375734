import { TransportService } from "./transportService";
const transportService = new TransportService();

const ServiceCentersApi = {
  getServiceCenters: filters => transportService.requestData("GetServiceCenters", null, { ...filters }),
  getServiceCenter: id => transportService.requestData("GetServiceCenter", null, null, id),
  createServiceCenter: formData => transportService.requestData("CreateServiceCenter", formData),
  updateServiceCenter: formData => transportService.requestData("UpdateServiceCenter", formData, null, formData.id),
  deleteServiceCenter: id => transportService.requestData("DeleteServiceCenter", null, null, id),
};

export default ServiceCentersApi;
