import * as types from "../actionTypes";

const initialState = {
  authLogsDataObj: {},
  authLogsDataError: false,
  authLogsDataLoading: true,
};

const AuthLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_AUTH_LOGS_STARTED:
      return {
        ...state,
        authLogsDataError: false,
        authLogsDataLoading: true,
      };
    case types.FETCHING_AUTH_LOGS_SUCCESS:
      return {
        ...state,
        authLogsDataObj: action.payload,
        authLogsDataLoading: false,
      };
    case types.FETCHING_AUTH_LOGS_FAIL:
      return {
        ...state,
        authLogsDataError: true,
        authLogsDataLoading: false,
      };
    default:
      return state;
  }
};

export default AuthLogsReducer;
