import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import CompanyAddForm from "./components/CompanyAddForm";

// Actions
import { addCompany } from "../../store/actions/companiesActions";

// Services
import { ValidationService } from "../../js/validationService";
const validationService = new ValidationService();

export default function CompanyAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const companiesDataObj = useSelector(state => state.companies.companiesDataObj);

  const handleClose = () => {
    const previousLocation = validationService.getPreviousHistoryLocationPathname(history.location.pathname)[0];

    history.push(`/${previousLocation}`);
  };

  return (
    <CompanyAddForm
      companiesDataObj={companiesDataObj}
      handleClose={handleClose}
      addCompany={addCompany}
      dispatch={dispatch}
    />
  );
}
