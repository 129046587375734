import React from "react";
import PropTypes from "prop-types";

// Components
import IconicBool from "./components/IconicBool";

export const InfoBlockSimple = ({ label, textData }) => {
  if (textData === null || textData === "") return null;

  return (
    <div className="info-block">
      <p className="info-block-label">{label}</p>
      <p className="info-block-txt">
        <IconicBool>{textData}</IconicBool>
      </p>
    </div>
  );
};

InfoBlockSimple.propTypes = {
  label: PropTypes.string.isRequired,
  textData: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
};
