import React from "react";
import { Redirect, Route } from "react-router-dom";

import Header from "../components/Header";
import SideMenu from "../components/SideMenu";

const DashboardLayout = ({ isUserAuthorized, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <>
          {!isUserAuthorized ? (
            <Redirect to={{ pathname: "/" }} />
          ) : (
            <>
              <Header />
              <main className="main">
                <SideMenu />
                <div className="main-content">
                  <Component {...matchProps} />
                </div>
              </main>
            </>
          )}
        </>
      )}
    />
  );
};

export default DashboardLayout;
