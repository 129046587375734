import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Material UI
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

// Components
import Loader from "./Loader";

// Utils
import extractValueFromNestedObject from "../utils/extractValueFromNestedObject";

const TableComponent = ({ columns, generalData, onRowClick, dataIsLoading, isCanAddNewItem, onPageChange }) => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => onPageChange(newPage);

  const handleChangeRowsPerPage = event => setRowsPerPage(+event.target.value);

  const handleRowClick = row => {
    if (typeof onRowClick === "function") {
      onRowClick(row);
    }
  };

  const formatTableCellValue = (column, row) => {
    const value = extractValueFromNestedObject(column.key, row);

    let outputCellValue;

    if (column.isBool) {
      value === false ? (outputCellValue = <CloseIcon />) : (outputCellValue = <CheckIcon />);
    } else if (column.isTime) {
      outputCellValue = value.slice(0, -7);
    } else {
      outputCellValue = value;
    }

    return outputCellValue;
  };

  return (
    <>
      {dataIsLoading ? (
        <Loader isSection={true} />
      ) : (
        <div className="table-container">
          {isCanAddNewItem && (
            <div className="table-top">
              <Button
                component={Link}
                to={`${history.location.pathname}/add`}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            </div>
          )}
          {generalData.data.length > 0 ? (
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell key={index}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {generalData.data.map(row => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.uuid ? row.uuid : row.id}
                          onClick={() => handleRowClick(row)}
                        >
                          {columns.map((column, index) => {
                            const cellValue = formatTableCellValue(column, row);

                            return <TableCell key={index}>{cellValue}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {generalData.total && (
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  count={generalData.total}
                  rowsPerPage={rowsPerPage}
                  page={generalData.current_page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Paper>
          ) : (
            <p style={{ textAlign: "center", fontSize: 40, marginTop: 100 }}>
              Sorry, nothing found <span style={{ fontSize: 70 }}>&#129335;</span>
            </p>
          )}
        </div>
      )}
    </>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  generalData: PropTypes.object.isRequired,
  onRowClick: PropTypes.func,
  dataIsLoading: PropTypes.bool.isRequired,
  isCanAddNewItem: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired,
};

export default TableComponent;
