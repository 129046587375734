import React from "react";
import PropTypes from "prop-types";

import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const TimePicker = ({ name, value, label, onChange }) => {
  const handleTimeChange = date => onChange(date, name);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        name={name}
        label={label}
        ampm={false}
        value={value ? new Date(`01/01/1970 ${value}`) : value}
        inputVariant="outlined"
        onChange={handleTimeChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        fullWidth
        clearable
      />
    </MuiPickersUtilsProvider>
  );
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TimePicker.defaultProps = {
  value: null,
};

export default TimePicker;
