import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Components
import ServiceCenterEditForm from "./components/ServiceCenterEditForm";

// Actions
import { updateServiceCenter, deleteServiceCenter } from "../../store/actions/serviceCentersActions";

export default function ServiceCenterEdit({ currentServiceCenter, serviceCentersData, handleClose }) {
  const dispatch = useDispatch();
  const serviceCentersFilters = useSelector(state => state.serviceCenters.serviceCentersFilters);

  return (
    <ServiceCenterEditForm
      currentServiceCenter={currentServiceCenter}
      serviceCentersData={serviceCentersData}
      handleClose={handleClose}
      updateServiceCenter={updateServiceCenter}
      deleteServiceCenter={deleteServiceCenter}
      serviceCentersFilters={serviceCentersFilters}
      dispatch={dispatch}
    />
  );
}

ServiceCenterEdit.propTypes = {
  currentServiceCenter: PropTypes.object.isRequired,
  serviceCentersData: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};
