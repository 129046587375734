import * as types from "../actionTypes";
import UsersApi from "../../api/usersApi";
import { openNotifier } from "./notificationActions";

export const fetchUsers = (page, dispatch) => {
  dispatch({ type: types.FETCHING_USERS_STARTED });

  UsersApi.getUsers(page)
    .then(response => dispatch({ type: types.FETCHING_USERS_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_USERS_FAIL }));
};

const fetchUser = (userUuid, dispatch) => {
  dispatch({ type: types.FETCHING_CURRENT_USER_STARTED });

  UsersApi.getUser(userUuid)
    .then(response => dispatch({ type: types.FETCHING_CURRENT_USER_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_CURRENT_USER_FAIL }));
};

export const searchUser = (guery, dispatch) => {
  dispatch({ type: types.FETCHING_USERS_STARTED });

  UsersApi.searchUser(guery)
    .then(response => dispatch({ type: types.FETCHING_USERS_SUCCESS, payload: response }))
    .catch(() => dispatch({ type: types.FETCHING_USERS_FAIL }));
};

export const getUser = (userUuid, usersDataObj, dispatch) => {
  if (Object.keys(usersDataObj).length) {
    const currentUser = usersDataObj.data?.filter(user => user.uuid === userUuid);

    dispatch({ type: types.FETCHING_CURRENT_USER_SUCCESS, payload: currentUser[0] });
  } else {
    fetchUser(userUuid, dispatch);
  }
};

export const updateUser = (formData, usersDataObj, closeForm, setSubmitting, dispatch) => {
  UsersApi.updateUser(formData)
    .then(response => {
      if (usersDataObj && Object.keys(usersDataObj).length) {
        const currentUserIndex = usersDataObj.data.findIndex(user => user.uuid === formData.uuid);

        if (currentUserIndex >= 0) {
          const updatedUsersDataObj = usersDataObj;

          updatedUsersDataObj.data[currentUserIndex] = {
            ...updatedUsersDataObj.data[currentUserIndex],
            ...response.data,
          };

          dispatch({ type: types.USERS_UPDATED, payload: updatedUsersDataObj });
        }
      }

      dispatch({ type: types.CURRENT_USER_UPDATED, payload: response.data });

      closeForm();
      openNotifier("success", "User has been updated!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `User wasn't updated. ${error}`, dispatch);
    });
};

export const deleteUser = (userUuid, usersDataObj, closeForm, setSubmitting, dispatch) => {
  setSubmitting(true);

  UsersApi.deleteUser(userUuid)
    .then(() => {
      fetchUsers(usersDataObj.current_page, dispatch);
      closeForm();
      openNotifier("warning", "User has been deleted!", dispatch);
    })
    .catch(error => {
      setSubmitting(false);
      openNotifier("error", `User wasn't deleted. ${error}`, dispatch);
    });
};

export const activateUser = (currentUser, usersDataObj, dispatch) => {
  UsersApi.activateUser(currentUser.uuid)
    .then(() => {
      dispatch({ type: types.CURRENT_USER_UPDATED, payload: { ...currentUser, is_active: true } });

      fetchUsers(usersDataObj.current_page, dispatch);
      openNotifier("success", "User has been activated!", dispatch);
    })
    .catch(error => openNotifier("error", `User wasn't activated. ${error}`, dispatch));
};
