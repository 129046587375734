import React, { useState } from "react";
import { withFormik } from "formik";
import Swal from "sweetalert2";
import format from "date-fns/format";
import PropTypes from "prop-types";

// Components
import DataPicker from "../../../components/DataPicker";
import {
  SimpleInput,
  EmailInput,
  PhoneInput,
  PasswordInput,
  AutocompleteInput,
  CheckBoxItem,
  ButtonWithLoader,
} from "../../../components/formElements";

// Services
import { ValidationService } from "../../../js/validationService";
const validationService = new ValidationService();

const Form = props => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleClose,
    usersDataObj,
    rolesDataObj,
    companiesDataObj,
    setFieldValue,
    deleteUser,
    dispatch,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleDelete = userUuid => {
    Swal.fire({
      title: "Do you want to delete this User?",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: "#d14529",
    }).then(result => result.isConfirmed && deleteUser(userUuid, usersDataObj, handleClose, setSubmitting, dispatch));
  };

  const setNewBirthdayDate = date => {
    const newBirthdayDate = {
      year: format(new Date(date), "yyyy"),
      month: format(new Date(date), "MM"),
      day: format(new Date(date), "dd"),
    };

    setFieldValue("birthday", newBirthdayDate);
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <form onSubmit={handleSubmit} className="form">
      <p className="form-title">General settings</p>

      <div className="form-block">
        <div className="form-item">
          <SimpleInput name="uuid" label="Uuid" value={values.uuid} disabled />
        </div>

        <div className="form-item">
          <SimpleInput
            name="firstName"
            label="First name"
            value={values.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.firstName}
            autoFocus
          />
        </div>

        <div className="form-item">
          <SimpleInput
            name="lastName"
            label="Last name"
            value={values.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.lastName}
          />
        </div>

        <div className="form-item">
          <SimpleInput
            name="patronymic"
            label="Patronymic"
            value={values.patronymic}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.patronymic}
          />
        </div>

        <div className="form-item">
          <EmailInput
            label="Email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.email}
            required
          />
        </div>

        <div className="form-item">
          <PhoneInput
            name="phone"
            label="Phone"
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.phone}
          />
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <DataPicker
              name="birthday"
              value={
                values.birthday.year !== null
                  ? `${values.birthday.year}-${values.birthday.month}-${values.birthday.day}`
                  : null
              }
              label="Birthday date"
              onChange={value => {
                setNewBirthdayDate(value);
              }}
            />
          </div>
        </div>

        <div className="form-item">
          <PasswordInput
            handleClickShowPassword={handleClickShowPassword}
            showPassword={showPassword}
            name="newPassword"
            label="New password"
            value={values.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.newPassword}
          />
        </div>
      </div>

      <p className="form-title">Corporate settings</p>

      <div className="form-block">
        <div className="form-item">
          <AutocompleteInput
            onChange={(event, value) => setFieldValue("role", value)}
            options={rolesDataObj.data}
            name="role"
            label="Role"
            defaultValue={values.role}
          />
        </div>

        <div className="form-item">
          <AutocompleteInput
            multiple
            onChange={(event, value) => setFieldValue("companies", value)}
            options={companiesDataObj.data}
            name="companies"
            label="Companies"
            defaultValue={values.companies}
          />
        </div>

        <div className="form-item">
          <SimpleInput
            name="position"
            label="Position"
            value={values.position}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.position}
          />
        </div>

        <div className="form-item">
          <SimpleInput
            name="requestedCompany"
            label="Requested company"
            value={values.requestedCompany}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.requestedCompany}
          />
        </div>
      </div>

      <div className="form-block">
        <div className="form-item">
          <CheckBoxItem
            checked={values.isUserCanSeeDistributors}
            onChange={handleChange}
            name="isUserCanSeeDistributors"
            label={values.isUserCanSeeDistributors ? "Product stock view active" : "Activate product stock view"}
          />
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader type="submit" text="Save" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />

        <ButtonWithLoader
          text="Delete"
          onClick={() => handleDelete(values.uuid)}
          className="delete"
          isSubmitting={isSubmitting}
        />
      </div>
    </form>
  );
};

const UserEditForm = withFormik({
  mapPropsToValues: e => {
    return {
      uuid: e.currentUser.uuid,
      firstName: e.currentUser.first_name,
      lastName: e.currentUser.last_name ? e.currentUser.last_name : "",
      patronymic: e.currentUser.patronymic ? e.currentUser.patronymic : "",
      newPassword: "",
      email: e.currentUser.email,
      phone: e.currentUser.phone ? e.currentUser.phone : "",
      birthday: e.currentUser.birthday,
      position: e.currentUser.position ? e.currentUser.position : "",
      requestedCompany: e.currentUser.requested_company ? e.currentUser.requested_company : "",
      role: e.currentUser.role,
      companies: e.currentUser.companies,
      initialData: e.currentUser,
      usersDataObj: e.usersDataObj,
      companiesDataObj: e.companiesDataObj.data,
      handleClose: e.handleClose,
      deleteUser: e.deleteUser,
      updateUser: e.updateUser,
      isUserCanSeeDistributors: e.currentUser.is_user_can_see_distributors,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};
    if (!values.firstName) {
      errors.firstName = "Field is Required";
    }

    if (!values.email) {
      errors.email = "Field is Required";
    }

    if (!validationService.isEmailValid(values.email)) {
      errors.email = "Email doesn't valid";
    }

    if (values.phone && !validationService.isPhoneValid(values.phone)) {
      errors.phone = "Phone doesn't valid";
    }

    if (values.newPassword.length > 0 && values.newPassword.length < 6) {
      errors.newPassword = "Must be at least 6 characters";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    const data = {
      uuid: values.uuid,
      first_name: values.firstName,
      last_name: values.lastName,
      patronymic: values.patronymic,
      email: values.email,
      phone: values.phone,
      day: values.birthday.day,
      month: values.birthday.month,
      year: values.birthday.year,
      role_id: values.role ? values.role.id : null,
      position: values.position,
      requested_company: values.requestedCompany,
      is_user_can_see_distributors: values.isUserCanSeeDistributors,
    };

    values.newPassword.length > 0 && (data.password = values.newPassword);

    const initialUserCompanies = JSON.stringify(values.companies);
    const companiesFromForm = JSON.stringify(values.initialData.companies);

    if (initialUserCompanies !== companiesFromForm) {
      const companiesIds = values.companies.map(company => company.id);

      data.company_ids = companiesIds;
    }

    values.updateUser(data, values.usersDataObj, values.handleClose, setSubmitting, values.dispatch);
  },
})(Form);

UserEditForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  usersDataObj: PropTypes.object.isRequired,
  rolesDataObj: PropTypes.object.isRequired,
  companiesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserEditForm;
