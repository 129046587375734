import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import TableComponent from "./components/TableComponent";

// Actions
import { fetchServiceCenters } from "../../store/actions/serviceCentersActions";

const tableColumns = [
  { label: "Title", key: "title" },
  { label: "City", key: "city" },
  { label: "Street", key: "street" },
  { label: "Phone", key: "phone" },
  { label: "Is active", key: "is_active", isBool: true },
  { label: "Deleted", key: "deleted_at", isBool: true },
];

export default function ServiceCentersList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const serviceCentersData = useSelector(state => state.serviceCenters.serviceCentersData);
  const serviceCentersDataLoading = useSelector(state => state.serviceCenters.serviceCentersDataLoading);
  const serviceCentersFilters = useSelector(state => state.serviceCenters.serviceCentersFilters);

  useEffect(() => !serviceCentersData.length && fetchServiceCenters(serviceCentersFilters, dispatch), []);

  const showItemByClick = serviceCenterId => history.push(`${history.location.pathname}/${serviceCenterId.id}`);

  return (
    <TableComponent
      dataIsLoading={serviceCentersDataLoading}
      columns={tableColumns}
      generalData={serviceCentersData}
      onRowClick={showItemByClick}
      isCanAddNewItem
    />
  );
}
