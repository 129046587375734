import React from "react";
import PropTypes from "prop-types";

export const WorkingHours = ({ label, textData }) => {
  if (!textData) return null;

  return (
    <div className="info-block">
      <p className="info-block-label">{label}</p>
      {Object.keys(textData).map((key, index) => {
        return (
          <span className="info-block-txt-item" key={index}>
            <span className="info-block-txt-item-label">{key}:</span>
            <span className="info-block-txt-item-value">
              {textData[key].map((day, index) => {
                return (
                  <span key={index}>
                    {Object.keys(day).map((hours, index) => {
                      return (
                        <span key={index}>
                          {day[hours]} {index === 0 ? "- " : ""}
                        </span>
                      );
                    })}
                  </span>
                );
              })}
            </span>
          </span>
        );
      })}
    </div>
  );
};

WorkingHours.propTypes = {
  label: PropTypes.string.isRequired,
  textData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
