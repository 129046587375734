import React from "react";
import PropTypes from "prop-types";

// Components
import { InfoBlockSimple } from "../../../components/InfoBlocks/InfoBlockSimple";

// Material UI
import Button from "@material-ui/core/Button";

const CompanyInfo = ({ currentCompany, handleClose }) => {
  return (
    <div className="info">
      <div className="info-content">
        <InfoBlockSimple label={"Id"} textData={currentCompany.id} />
        <InfoBlockSimple label={"Company Name"} textData={currentCompany.name} />
      </div>

      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
        Close
      </Button>
    </div>
  );
};

CompanyInfo.propTypes = {
  currentCompany: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CompanyInfo;
