import React from "react";
import PropTypes from "prop-types";

// Components
import { InfoBlockSimple } from "../../../components/InfoBlocks/InfoBlockSimple";

// Material UI
import Button from "@material-ui/core/Button";

const RoleInfo = ({ currentRole, handleClose }) => {
  return (
    <div className="info">
      <div className="info-content">
        <InfoBlockSimple label={"Id"} textData={currentRole.id} />
        <InfoBlockSimple label={"Name"} textData={currentRole.name} />
        <InfoBlockSimple label={"Slug"} textData={currentRole.slug} />
      </div>

      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
        Close
      </Button>
    </div>
  );
};

RoleInfo.propTypes = {
  currentRole: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default RoleInfo;
