import * as types from "../actionTypes";

const initialState = {
  serviceCentersData: [],
  serviceCentersDataError: false,
  serviceCentersDataLoading: true,
  serviceCentersFilters: {
    is_active: true,
    is_deleted: false,
  },

  currentServiceCenterData: {},
  currentServiceCenterDataError: false,
  currentServiceCenterDataLoading: true,
};

const ServiceCentersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_SERVICE_CENTERS_STARTED:
      return {
        ...state,
        serviceCentersDataError: false,
        serviceCentersDataLoading: true,
      };
    case types.FETCHING_SERVICE_CENTERS_SUCCESS:
      return {
        ...state,
        serviceCentersData: action.payload,
        serviceCentersDataLoading: false,
      };
    case types.FETCHING_SERVICE_CENTERS_FAIL:
      return {
        ...state,
        serviceCentersDataError: true,
        serviceCentersDataLoading: false,
      };
    case types.SERVICE_CENTERS_UPDATED: {
      return {
        ...state,
        serviceCentersData: action.payload,
      };
    }
    case types.SERVICE_CENTERS_FILTERS_CHANGED: {
      return {
        ...state,
        serviceCentersFilters: {
          ...state.serviceCentersFilters,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default ServiceCentersReducer;
