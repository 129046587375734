import * as types from "../actionTypes";

const initialState = {
  notifierIsOpen: false,
  notifierMessage: "",
  notifierVariant: "",
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SNACK_BAR_NOTIFIER:
      return {
        ...state,
        notifierIsOpen: true,
        notifierMessage: action.payload.message,
        notifierVariant: action.payload.variant,
      };
    case types.HIDE_SNACK_BAR_NOTIFIER:
      return {
        ...state,
        notifierIsOpen: false,
        notifierMessage: action.payload.message,
        notifierVariant: action.payload.variant,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
