import * as types from "../actionTypes";
import AuthLogsApi from "../../api/authLogsApi";

export const getAuthLogsData = (page, dispatch) => {
  dispatch({ type: types.FETCHING_AUTH_LOGS_STARTED });

  AuthLogsApi.getAuthLogs(page)
    .then(response => dispatch({ type: types.FETCHING_AUTH_LOGS_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_AUTH_LOGS_FAIL }));
};

export const searchAuthLogs = (guery, dispatch) => {
  dispatch({ type: types.FETCHING_AUTH_LOGS_STARTED });

  AuthLogsApi.searchAuthLogs(guery)
    .then(response => dispatch({ type: types.FETCHING_AUTH_LOGS_SUCCESS, payload: response }))
    .catch(() => dispatch({ type: types.FETCHING_AUTH_LOGS_FAIL }));
};
