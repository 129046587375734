import React from "react";
import { useDispatch } from "react-redux";
import SearchBlock from "../../components/SearchBlock";
import UsersList from "./UsersList";

// Actions
import { searchUser, fetchUsers } from "../../store/actions/usersActions";

export default function UsersPage() {
  const dispatch = useDispatch();

  return (
    <>
      <SearchBlock getSearch={searchUser} fetchData={fetchUsers} dispatch={dispatch} />
      <UsersList />
    </>
  );
}
