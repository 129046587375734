import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

// Material UI
import TextField from "@material-ui/core/TextField";

// Components
import { ButtonWithLoader } from "../components/formElements";

// Actions
import { handleLoginSubmit, setUserLoginError } from "../store/actions/loginActions";

export default function SignIn() {
  const dispatch = useDispatch();
  const isUserAuthorized = useSelector(state => state.login.isUserAuthorized);
  const isLoginSubmitting = useSelector(state => state.login.isLoginSubmitting);
  const isLoginError = useSelector(state => state.login.isLoginError);
  const [formData, setFormData] = useState({});

  // Input value handler
  const handleChange = name => e => {
    setFormData({ ...formData, [name]: e.target.value });

    isLoginError && setUserLoginError(false);
  };

  const onSubmit = e => {
    e.preventDefault();
    const data = { email: formData.email, password: formData.password };
    data.email && data.password ? handleLoginSubmit(data, dispatch) : setUserLoginError(true);
  };

  return (
    <>
      {isUserAuthorized ? (
        <Redirect to={"/roles"} />
      ) : (
        <div className="login">
          <p className="login-title">Sign in</p>
          <form children="login-form" onSubmit={e => onSubmit(e)} noValidate>
            <TextField
              error={isLoginError}
              helperText={isLoginError ? "Field is Required" : ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChange("email")}
            />
            <TextField
              error={isLoginError}
              helperText={isLoginError ? "Field is Required" : ""}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange("password")}
            />

            <ButtonWithLoader
              type="submit"
              text="Sign In"
              className="submit"
              isSubmitting={isLoginSubmitting}
              fullWidth
            />
          </form>
        </div>
      )}
    </>
  );
}
