export default function extractValueFromNestedObject(propertyName, object) {
  const parts = propertyName.split(".");
  let length = parts.length;
  let i;
  let property = object || this;

  for (i = 0; i < length; i++) {
    if (property !== null) {
      property = property[parts[i]];
    } else return null;
  }

  return property;
}
