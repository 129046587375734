import React from "react";
import PropTypes from "prop-types";

// Components
import { InfoBlockSimple } from "../../../components/InfoBlocks/InfoBlockSimple";
import { InfoBlockComplex } from "../../../components/InfoBlocks/InfoBlockComplex";
import { WorkingHours } from "./WorkingHours";

// Material UI
import Button from "@material-ui/core/Button";

// Utils
import formatDate from "../../../utils/formatDate";

const ServiceCenterInfo = ({ currentServiceCenter, handleClose }) => {
  return (
    <div className="info">
      <div className="info-content">
        <InfoBlockSimple label={"Id"} textData={currentServiceCenter.id} />
        <InfoBlockSimple label={"Title"} textData={currentServiceCenter.title} />
        <InfoBlockSimple label={"City"} textData={currentServiceCenter.city} />
        <InfoBlockSimple label={"Street"} textData={currentServiceCenter.street} />
        <InfoBlockSimple label={"Phone"} textData={currentServiceCenter.phone} />
        <InfoBlockSimple label={"Latitude"} textData={currentServiceCenter.lat} />
        <InfoBlockSimple label={"Longitude"} textData={currentServiceCenter.lng} />
        <InfoBlockComplex label={"Devices"} textData={currentServiceCenter.devices} />
        <InfoBlockSimple label={"Comment"} textData={currentServiceCenter.comment} />
        <WorkingHours label={"Opening hours"} textData={currentServiceCenter.openingHours} />
        <InfoBlockSimple label={"Is active"} textData={currentServiceCenter.is_active} />
        <InfoBlockSimple label={"Created at"} textData={formatDate(currentServiceCenter.created_at)} />
        <InfoBlockSimple label={"Deleted at"} textData={formatDate(currentServiceCenter.deleted_at)} />
        <InfoBlockSimple label={"Updated at"} textData={formatDate(currentServiceCenter.updated_at)} />
      </div>

      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
        Close
      </Button>
    </div>
  );
};

ServiceCenterInfo.propTypes = {
  currentServiceCenter: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ServiceCenterInfo;
