import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import Loader from "../../components/Loader";
import TabPanel from "../../components/TabPanel";
import RoleInfo from "./components/RoleInfo";
import RoleEdit from "./RoleEdit";

// Actions
import { fetchRoles } from "../../store/actions/rolesActions";

// Services
import { ValidationService } from "../../js/validationService";
const validationService = new ValidationService();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RoleItem() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const rolesDataObj = useSelector(state => state.roles.rolesDataObj);
  const [value, setValue] = useState(0);
  const [currentRoleData, setCurrentRoleData] = useState({});
  const [roleItemIsLoading, setRoleItemIsLoading] = useState(true);
  const [roleItemNotFound, setRoleItemNotFound] = useState(false);
  const roleId = Number.parseInt(useParams().roleId);

  const handleChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    if (Object.keys(rolesDataObj).length) {
      const currentRole = rolesDataObj?.data?.filter(role => role.id === roleId);

      currentRole.length > 0 ? setCurrentRoleData(currentRole[0]) : setRoleItemNotFound(true);
      setRoleItemIsLoading(false);
    } else {
      fetchRoles(1, dispatch);
    }
  }, []);

  const handleClose = () => {
    const previousLocation = validationService.getPreviousHistoryLocationPathname(history.location.pathname)[0];

    history.push(`/${previousLocation}`);
  };

  return (
    <>
      {roleItemIsLoading ? (
        <Loader isSection={true} />
      ) : roleItemNotFound ? (
        "The current item didn't found"
      ) : (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="auto tabs"
            >
              <Tab label="Role info" />
              <Tab label="Edit Role" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <RoleInfo currentRole={currentRoleData} handleClose={handleClose} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RoleEdit
              currentRole={currentRoleData}
              rolesDataObj={rolesDataObj}
              handleClose={handleClose}
              dispatch={dispatch}
            />
          </TabPanel>
        </div>
      )}
    </>
  );
}
