import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import format from "date-fns/format";

// Components
import { SimpleInput, ButtonWithLoader, PhoneInput, CheckBoxItem } from "../../../components/formElements";
import TimePicker from "../../../components/TimePicker";
import TitleIcon from "@material-ui/icons/Title";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import HouseOutlinedIcon from "@material-ui/icons/HouseOutlined";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";

// Services
import { ValidationService } from "../../../js/validationService";
const validationService = new ValidationService();

const Form = props => {
  const { values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, handleClose } = props;

  const setOpeningHours = (date, inputName) => {
    const newOpeningHours = date && String(date).trim() !== "Invalid Date" ? format(new Date(date), "HH:mm") : date;

    setFieldValue(inputName, newOpeningHours);
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <p className="form-title">Add new Service center</p>

      <div className="form-block">
        <div className="form-item">
          <div className="__with-icon">
            <TitleIcon />
            <SimpleInput
              name="title"
              label="Title"
              value={values.title}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.title}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <HomeWorkOutlinedIcon />
            <SimpleInput
              name="city"
              label="City"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.city}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <HouseOutlinedIcon />
            <SimpleInput
              name="street"
              label="Street"
              value={values.street}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.street}
            />
          </div>
        </div>

        <div className="form-item">
          <PhoneInput
            name="phone"
            label="Phone"
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.phone}
          />
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <ExploreOutlinedIcon />
            <SimpleInput
              name="latitude"
              label="Latitude"
              value={values.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.latitude}
              placeholder={"-49.786882"}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <ExploreOutlinedIcon />
            <SimpleInput
              name="longitude"
              label="Longitude"
              value={values.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.longitude}
              placeholder={"30.128682"}
            />
          </div>
        </div>

        <div className="form-item">
          <CheckBoxItem checked={values.active} onChange={handleChange} name="active" label={"Is active"} />
        </div>
      </div>

      <p className="form-title">Devices</p>

      <div className="form-block">
        <div className="form-item">
          <CheckBoxItem
            checked={values.repair_notebook}
            onChange={handleChange}
            name="repair_notebook"
            label={"Notebooks"}
          />
        </div>

        <div className="form-item">
          <CheckBoxItem checked={values.repair_tablet} onChange={handleChange} name="repair_tablet" label={"Tablets"} />
        </div>

        <div className="form-item">
          <CheckBoxItem checked={values.repair_mobile} onChange={handleChange} name="repair_mobile" label={"Mobiles"} />
        </div>

        <div className="form-item">
          <CheckBoxItem
            checked={values.repair_other}
            onChange={handleChange}
            name="repair_other"
            label={"Other devices"}
          />
        </div>
      </div>

      <p className="form-title">Opening hours</p>

      <div className="form-block">
        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.monday.start"
              label="Monday start"
              value={values.openingHours.monday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.monday.end"
              label="Monday end"
              value={values.openingHours.monday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.tuesday.start"
              label="Tuesday start"
              value={values.openingHours.tuesday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.tuesday.end"
              label="Tuesday end"
              value={values.openingHours.tuesday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.wednesday.start"
              label="Wednesday start"
              value={values.openingHours.wednesday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.wednesday.end"
              label="Wednesday end"
              value={values.openingHours.wednesday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.thursday.start"
              label="Thursday start"
              value={values.openingHours.thursday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.thursday.end"
              label="Thursday end"
              value={values.openingHours.thursday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.friday.start"
              label="Friday start"
              value={values.openingHours.friday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.friday.end"
              label="Friday end"
              value={values.openingHours.friday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.saturday.start"
              label="Saturday start"
              value={values.openingHours.saturday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.saturday.end"
              label="Saturday end"
              value={values.openingHours.saturday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.sunday.start"
              label="Sunday start"
              value={values.openingHours.sunday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.sunday.end"
              label="Sunday end"
              value={values.openingHours.sunday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <SimpleInput
            name="comment"
            label="Comment"
            value={values.comment}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.comment}
            placeholder={"Comment"}
          />
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader text="Add" type="submit" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

const ServiceCenterAddForm = withFormik({
  mapPropsToValues: e => {
    return {
      title: "",
      city: "",
      street: "",
      phone: "",
      latitude: "",
      longitude: "",
      active: false,
      repair_mobile: false,
      repair_notebook: false,
      repair_tablet: false,
      repair_other: false,
      openingHours: {
        monday: { start: null, end: null },
        tuesday: { start: null, end: null },
        wednesday: { start: null, end: null },
        thursday: { start: null, end: null },
        friday: { start: null, end: null },
        saturday: { start: null, end: null },
        sunday: { start: null, end: null },
      },
      comment: "",

      serviceCentersData: e.serviceCentersData,
      handleClose: e.handleClose,
      addServiceCenter: e.addServiceCenter,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};

    if (!values.title) {
      errors.title = "Field is Required";
    }

    if (!values.city) {
      errors.city = "Field is Required";
    }

    if (!values.street) {
      errors.street = "Field is Required";
    }

    if (!values.latitude) {
      errors.latitude = "Field is Required";
    }

    if (values.latitude > 180 || values.latitude < -180) {
      errors.latitude = "Latitude value should be between 180 and -180";
    }

    if (!values.longitude) {
      errors.longitude = "Field is Required";
    }

    if (values.longitude > 90 || values.longitude < -90) {
      errors.longitude = "Latitude value should be between 90 and -90";
    }

    if (values.phone && !validationService.isPhoneValid(values.phone)) {
      errors.phone = "Phone doesn't valid";
    }

    if (!values.phone) {
      errors.phone = "Field is Required";
    }

    if (values.comment.length >= 100) {
      errors.comment = "Should be less then 100 characters";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    const {
      title,
      city,
      street,
      phone,
      latitude,
      longitude,
      active,
      repair_mobile,
      repair_notebook,
      repair_tablet,
      repair_other,
      openingHours,
      comment,
      serviceCentersData,
      addServiceCenter,
      handleClose,
      dispatch,
    } = values;

    // Data constructor
    const data = {
      title,
      city,
      street,
      phone,
      latitude: Number(latitude),
      longitude: Number(longitude),
      active,
      repair_mobile,
      repair_notebook,
      repair_tablet,
      repair_other,
      working_hours: {
        ...(() => {
          let openingHoursObj = {};

          for (const [key, value] of Object.entries(openingHours)) {
            openingHoursObj[key] = value.start && value.end ? [{ start: value.start, end: value.end }] : [];
          }

          return openingHoursObj;
        })(),
      },
      comment,
    };

    const successCallback = () => {
      setSubmitting(false);
      handleClose();
    };

    const failCallback = () => setSubmitting(false);

    addServiceCenter(data, serviceCentersData, successCallback, failCallback, dispatch);
  },
})(Form);

ServiceCenterAddForm.propTypes = {
  serviceCentersData: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ServiceCenterAddForm;
