import React from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";

// Layouts
import DefaultLayout from "./layouts/defaultLayout";
import DashboardLayout from "./layouts/dashboardLayout";

// Views
import SignIn from "./views/SignIn";
import RolesList from "./views/roles/RolesList";
import RoleAdd from "./views/roles/RoleAdd";
import RoleItem from "./views/roles/RoleItem";
import CompaniesList from "./views/companies/CompaniesList";
import CompanyAdd from "./views/companies/CompanyAdd";
import CompanyItem from "./views/companies/CompanyItem";
import UsersPage from "./views/users/UsersPage";
import UserItem from "./views/users/UserItem";
import AuthLogs from "./views/AuthLogs";
import ServiceCentersList from "./views/service-centers/ServiceCentersList";
import ServiceCenterItem from "./views/service-centers/ServiceCenterItem";
import ServiceCenterAdd from "./views/service-centers/ServiceCenterAdd";
import NotFound from "./views/NotFound";

const Routes = ({ isUserAuthorized }) => {
  const { pathname } = useLocation();

  return (
    <Switch>
      <DefaultLayout exact path="/" component={SignIn} />
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <DashboardLayout exact path={"/roles"} component={RolesList} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout exact path={"/roles/add"} component={RoleAdd} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout exact path={"/roles/:roleId"} component={RoleItem} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout exact path={"/companies"} component={CompaniesList} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout exact path={"/companies/add"} component={CompanyAdd} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout
        exact
        path={"/companies/:companyId"}
        component={CompanyItem}
        isUserAuthorized={isUserAuthorized}
      />
      <DashboardLayout exact path={"/users"} component={UsersPage} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout exact path={"/users/:userUuid"} component={UserItem} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout exact path={["/auth_logs"]} component={AuthLogs} isUserAuthorized={isUserAuthorized} />
      <DashboardLayout
        exact
        path={["/service-centers"]}
        component={ServiceCentersList}
        isUserAuthorized={isUserAuthorized}
      />
      <DashboardLayout
        exact
        path={["/service-centers/add"]}
        component={ServiceCenterAdd}
        isUserAuthorized={isUserAuthorized}
      />
      <DashboardLayout
        exact
        path={["/service-centers/:id"]}
        component={ServiceCenterItem}
        isUserAuthorized={isUserAuthorized}
      />
      {/* Not found page */}
      <DefaultLayout component={NotFound} />
    </Switch>
  );
};

export default Routes;
