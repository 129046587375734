import * as types from "../actionTypes";

const initialState = {
  usersDataObj: {},
  usersDataError: false,
  usersDataLoading: true,

  currentUserData: {},
  currentUserError: false,
  currentUserDataLoading: true,
};

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_USERS_STARTED:
      return {
        ...state,
        usersDataError: false,
        usersDataLoading: true,
      };
    case types.FETCHING_USERS_SUCCESS:
      return {
        ...state,
        usersDataObj: action.payload,
        usersDataLoading: false,
      };
    case types.FETCHING_USERS_FAIL:
      return {
        ...state,
        usersDataError: true,
        usersDataLoading: false,
      };
    case types.USERS_UPDATED:
      return {
        ...state,
        usersDataObj: action.payload,
      };
    case types.FETCHING_CURRENT_USER_STARTED:
      return {
        ...state,
        currentUserError: false,
        currentUserDataLoading: true,
      };
    case types.FETCHING_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUserData: action.payload,
        currentUserDataLoading: false,
      };
    case types.FETCHING_CURRENT_USER_FAIL:
      return {
        ...state,
        currentUserError: true,
        currentUserDataLoading: false,
      };
    case types.CURRENT_USER_UPDATED:
      return {
        ...state,
        currentUserData: action.payload,
      };
    default:
      return state;
  }
};

export default UsersReducer;
