import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import loginReducer from "./reducers/loginReducer";
import RolesReducer from "./reducers/rolesReducer";
import CompaniesReducer from "./reducers/companiesReducer";
import UsersReducer from "./reducers/usersReducer";
import AuthLogsReducer from "./reducers/authLogsReducer";
import ServiceCentersReducer from "./reducers/serviceCentersReducer";
import NotificationReducer from "./reducers/notificationReducer";

let devtools = x => x;

if (process.env.NODE_ENV !== "production" && typeof window === "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

const Store = createStore(
  combineReducers({
    login: loginReducer,
    roles: RolesReducer,
    companies: CompaniesReducer,
    users: UsersReducer,
    authLogs: AuthLogsReducer,
    serviceCenters: ServiceCentersReducer,
    notifications: NotificationReducer,
  }),
  compose(applyMiddleware(thunk), devtools),
);

export default Store;
