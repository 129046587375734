import React from "react";
import { useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";

// Components
import SnackBarNotifierWrapper from "./SnackBarNotifierWrapper";

// Actions
import { closeNotifier } from "../../store/actions/notificationActions";

export default function Notifier({ notifierIsOpenState, variant, message }) {
  const dispatch = useDispatch();

  const handleClose = () => closeNotifier(variant, message, dispatch);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={notifierIsOpenState}
      autoHideDuration={variant === "error" || variant === "warning" ? 6000 : 4000}
      onClose={handleClose}
    >
      {/* Receiving variant like success, error, warning, info, success */}
      <SnackBarNotifierWrapper onClose={handleClose} variant={variant} message={message} />
    </Snackbar>
  );
}
