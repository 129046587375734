import React from "react";
import InputMask from "react-input-mask";

// Components
import Loader from "./Loader";

// Material UI
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";

export const ButtonWithLoader = ({ type, text, onClick, className, isSubmitting, variant, color, fullWidth }) => (
  <div className="button-container">
    <Button
      type={type ? type : "button"}
      className={className}
      onClick={onClick}
      disabled={isSubmitting ? true : false}
      variant={variant ? variant : "contained"}
      color={color ? color : "primary"}
      fullWidth={fullWidth}
    >
      {text}
    </Button>
    {isSubmitting && <Loader />}
  </div>
);

export function SimpleInput({
  label,
  value,
  onBlur,
  onChange,
  error,
  name,
  required,
  className,
  placeholder,
  variant,
  disabled,
  autoFocus,
}) {
  return (
    <div className="form-item-container">
      <TextField
        label={label}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        helperText={error ? error : ""}
        name={name}
        required={required}
        className={className}
        placeholder={placeholder}
        variant={variant ? variant : "outlined"}
        disabled={disabled}
        autoFocus={autoFocus}
        fullWidth
      />
    </div>
  );
}

export function EmailInput({
  id,
  label,
  value,
  onBlur,
  onChange,
  error,
  name,
  required,
  className,
  variant,
  disabled,
}) {
  return (
    <div className="form-item-container">
      <div className="__with-icon">
        <MailOutlineIcon />
        <TextField
          id={id}
          label={label}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          error={error}
          helperText={error ? error : ""}
          type="email"
          name={name ? name : "email"}
          required={required}
          className={className}
          variant={variant ? variant : "outlined"}
          disabled={disabled}
          fullWidth
        />
      </div>
    </div>
  );
}

export function PhoneInput({ id, label, value, onChange, error, name, required, className, variant }) {
  return (
    <div className="form-item-container">
      <div className="__with-icon">
        <PhoneIcon />
        <InputMask mask="+38 (099) 999 99 99" value={value} onChange={onChange}>
          {() => (
            <TextField
              id={id}
              label={label}
              error={error}
              helperText={error ? error : ""}
              name={name ? name : "phone"}
              type="tel"
              required={required}
              className={className}
              variant={variant ? variant : "outlined"}
              fullWidth
            />
          )}
        </InputMask>
      </div>
    </div>
  );
}

export function PasswordInput({
  handleClickShowPassword,
  showPassword,
  label,
  value,
  onBlur,
  onChange,
  error,
  name,
  required,
  className,
  variant,
  disabled,
}) {
  return (
    <div className="form-item-container">
      <TextField
        type={showPassword ? "text" : "password"}
        label={label}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        helperText={error ? error : ""}
        name={name}
        required={required}
        className={className}
        variant={variant ? variant : "outlined"}
        disabled={disabled}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export const AutocompleteInput = ({ onChange, options, name, label, defaultValue, multiple }) => (
  <div className="form-item-container">
    <Autocomplete
      multiple={multiple}
      defaultValue={defaultValue}
      name={name}
      onChange={onChange}
      options={options}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.name}
      renderInput={params => <TextField {...params} label={label} variant="outlined" />}
    />
  </div>
);

export const Switcher = ({ checked, onChange, name, label, color, disabled }) => (
  <div className="form-item-container">
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
          color={color ? color : "primary"}
          disabled={disabled}
        />
      }
      label={label}
    />
  </div>
);

export const CheckBoxItem = ({ checked, onChange, name, label, color, disabled }) => (
  <div className="form-item-container">
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={name}
          color={color ? color : "primary"}
          disabled={disabled}
        />
      }
      label={label}
    />
  </div>
);
