import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import Swal from "sweetalert2";

// Components
import { SimpleInput, ButtonWithLoader } from "../../../components/formElements";

// Services
import { ValidationService } from "../../../js/validationService";
const validationService = new ValidationService();

const Form = props => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleClose,
    rolesDataObj,
    deleteRole,
    dispatch,
  } = props;

  const handleDelete = roleId => {
    Swal.fire({
      title: "Do you want to delete this Role?",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: "#d14529",
    }).then(
      result => result.isConfirmed && deleteRole(roleId, rolesDataObj, values.handleClose, setSubmitting, dispatch),
    );
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="form-block">
        <div className="form-item">
          <SimpleInput name="id" label="Id" value={values.id} disabled />
        </div>
        <div className="form-item">
          <SimpleInput
            name="name"
            label="Name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.name}
          />
        </div>

        <div className="form-item">
          <SimpleInput
            name="slug"
            label="Slug"
            value={values.slug}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.slug}
          />
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader type="submit" text="Save" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />

        <ButtonWithLoader
          text="Delete"
          onClick={() => handleDelete(values.id)}
          className="delete"
          isSubmitting={isSubmitting}
        />
      </div>
    </form>
  );
};

const RoleEditForm = withFormik({
  mapPropsToValues: e => {
    return {
      id: e.currentRole.id,
      name: e.currentRole.name,
      slug: e.currentRole.slug,
      initialRoleName: e.currentRole.name,
      initialRoleSlug: e.currentRole.slug,
      rolesDataObj: e.rolesDataObj,
      handleClose: e.handleClose,
      updateRole: e.updateRole,
      deleteRole: e.deleteRole,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};
    if (!values.name) {
      errors.name = "Field is Required";
    }

    if (values.name === values.initialRoleName) {
      errors.name = "The name has already been taken";
    }

    if (!values.slug) {
      errors.slug = "Field is Required";
    }

    if (values.slug === values.initialRoleSlug) {
      errors.slug = "The name has already been taken";
    }

    if (!validationService.isLatinValid(values.slug)) {
      errors.slug = "Allowed only latin letters";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    setSubmitting(true);

    // Data constructor
    const data = { id: values.id, name: values.name, slug: values.slug };

    const UpdateCurrentRoleInfoInRolesList = () => {
      const currentRoleIndex = values.rolesDataObj.data.findIndex(role => role.id === values.id);

      values.rolesDataObj.data[currentRoleIndex] = data;
    };

    UpdateCurrentRoleInfoInRolesList();

    values.updateRole(data, values.rolesDataObj, values.handleClose, setSubmitting, values.dispatch);
  },
})(Form);

RoleEditForm.propTypes = {
  currentRole: PropTypes.object.isRequired,
  rolesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  deleteRole: PropTypes.func.isRequired,
};

export default RoleEditForm;
