import React from "react";
import PropTypes from "prop-types";

// Components
import RoleEditForm from "./components/RoleEditForm";

// Actions
import { deleteRole, updateRole } from "../../store/actions/rolesActions";

export default function RoleEdit({ currentRole, rolesDataObj, handleClose, dispatch }) {
  return (
    <RoleEditForm
      currentRole={currentRole}
      rolesDataObj={rolesDataObj}
      handleClose={handleClose}
      updateRole={updateRole}
      deleteRole={deleteRole}
      dispatch={dispatch}
    />
  );
}

RoleEdit.propTypes = {
  currentRole: PropTypes.object.isRequired,
  rolesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};
