import { TransportService } from "./transportService";
const transportService = new TransportService();

const RolesApi = {
  getRoles: (page = 1) => transportService.requestData("GetRoles", null, { page: page }),
  addRole: formData => transportService.requestData("AddRole", formData),
  updateRole: formData => transportService.requestData("UpdateRole", formData, null, formData.id),
  deleteRole: roleId => transportService.requestData("DeleteRole", null, null, roleId),
};

export default RolesApi;
