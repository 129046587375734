import regexRules from "../utils/regex";

export class ValidationService {
  static matches = (regex, value) => regex.test(value);
  // Replace sign in mobile
  checkValueInRegx = value => (value === undefined || value === null ? false : value.replace(/[^\d+]/g, ""));
  // Validate Email
  isEmailValid = value => ValidationService.matches(regexRules.email, value);
  // Validate phone
  isPhoneValid = value => ValidationService.matches(regexRules.phone, this.checkValueInRegx(value));
  // Latin data
  isLatinValid = value => ValidationService.matches(regexRules.latin, value);
  // Alphabetic data
  isAlphabeticValid = value => ValidationService.matches(regexRules.alpha, value);
  // Alphanumeric data
  isAlphaNumericValid = value => ValidationService.matches(regexRules.alpha_numeric, value);
  getPreviousHistoryLocationPathname = value => value.match(regexRules.previousHistoryLocationPathname);
}
