import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";

// Components
import { SimpleInput, ButtonWithLoader } from "../../../components/formElements";
import BusinessIcon from "@material-ui/icons/Business";

const Form = props => {
  const { values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, handleClose } = props;

  return (
    <form onSubmit={handleSubmit} className="form">
      <p className="form-title">Add new Company</p>

      <div className="form-block">
        <div className="form-item">
          <div className="__with-icon">
            <BusinessIcon />
            <SimpleInput
              name="name"
              label="Company name"
              onBlur={handleBlur}
              value={values.name}
              onChange={handleChange}
              error={errors.name}
            />
          </div>
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader text="Add" type="submit" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />
      </div>
    </form>
  );
};

const CompanyAddForm = withFormik({
  mapPropsToValues: e => {
    return {
      name: "",
      companiesDataObj: e.companiesDataObj,
      handleClose: e.handleClose,
      addCompany: e.addCompany,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};

    !values.name && (errors.name = "Field is Required");

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    // Data constructor
    const data = { name: values.name };

    values.addCompany(data, values.companiesDataObj, values.handleClose, setSubmitting, values.dispatch);
  },
})(Form);

CompanyAddForm.propTypes = {
  companiesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  addCompany: PropTypes.func.isRequired,
};

export default CompanyAddForm;
