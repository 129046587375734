import * as types from "../actionTypes";

import { UserModelService } from "../../js/userModelService";

// Actions
import { openNotifier } from "./notificationActions";

// Api
import LoginApi from "../../api/loginApi";

const allowUserToEnter = (response, dispatch) => {
  if (response.role?.slug === "admin") {
    dispatch({ type: types.USER_LOGIN_SUCCESS, isUserAuthorized: true, userData: response });
  } else {
    openNotifier("error", `You don't have permission to sign in`, dispatch);
    setUserLoginError(false, dispatch);
  }
};

export const setUserLoginError = (isError, dispatch) => dispatch({ type: types.USER_LOGIN_FAIL, payload: isError });

export const initializeState = dispatch => {
  const user = UserModelService.getUser;

  user
    .isLogin()
    .then(response => allowUserToEnter(response, dispatch))
    .catch(() => {})
    .finally(() => dispatch({ type: types.APP_LOADING_FINISHED, payload: false }));
};

export const handleLoginSubmit = (data, dispatch) => {
  dispatch({ type: types.USER_LOGIN_STARTED });

  LoginApi.auth(data)
    .then(res => {
      let user = new UserModelService();

      user.setToken(res.data.token).then(() => user.isLogin().then(response => allowUserToEnter(response, dispatch)));
    })
    .catch(error => setUserLoginError(true, dispatch));
};

export const userLogout = dispatch => {
  UserModelService.getUser.logout();

  dispatch({ type: types.USER_LOGOUT });
};

export const logoutUserIfSessionHasEnded = dispatch => {
  userLogout(dispatch);
  openNotifier("error", "Your session has been ended", dispatch);
};
