import React from "react";
import { withFormik } from "formik";
import Swal from "sweetalert2";
import format from "date-fns/format";
import PropTypes from "prop-types";

// Components
import TimePicker from "../../../components/TimePicker";
import { SimpleInput, PhoneInput, ButtonWithLoader, CheckBoxItem } from "../../../components/formElements";
import TitleIcon from "@material-ui/icons/Title";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import HouseOutlinedIcon from "@material-ui/icons/HouseOutlined";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";

// Services
import { ValidationService } from "../../../js/validationService";
const validationService = new ValidationService();

const Form = props => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleClose,
    setFieldValue,
    deleteServiceCenter,
    serviceCentersFilters,
    dispatch,
  } = props;

  const handleDelete = id => {
    Swal.fire({
      title: "Do you want to delete this service center?",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: "#d14529",
    }).then(
      result =>
        result.isConfirmed && deleteServiceCenter(id, serviceCentersFilters, handleClose, setSubmitting, dispatch),
    );
  };

  const setOpeningHours = (date, inputName) => {
    const newOpeningHours = date && String(date).trim() !== "Invalid Date" ? format(new Date(date), "HH:mm") : date;

    setFieldValue(inputName, newOpeningHours);
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <p className="form-title">General info</p>

      <div className="form-block">
        <div className="form-item">
          <SimpleInput name="id" label="Id" value={values.id} disabled />
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <TitleIcon />
            <SimpleInput
              name="title"
              label="Title"
              value={values.title}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.title}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <HomeWorkOutlinedIcon />
            <SimpleInput
              name="city"
              label="City"
              value={values.city}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.city}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <HouseOutlinedIcon />
            <SimpleInput
              name="street"
              label="Street"
              value={values.street}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.street}
            />
          </div>
        </div>

        <div className="form-item">
          <PhoneInput
            name="phone"
            label="Phone"
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.phone}
          />
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <ExploreOutlinedIcon />
            <SimpleInput
              name="latitude"
              label="Latitude"
              value={values.latitude}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.latitude}
              placeholder={"-49.786882"}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="__with-icon">
            <ExploreOutlinedIcon />
            <SimpleInput
              name="longitude"
              label="Longitude"
              value={values.longitude}
              onBlur={handleBlur}
              onChange={handleChange}
              error={errors.longitude}
              placeholder={"30.128682"}
            />
          </div>
        </div>

        <div className="form-item">
          <CheckBoxItem checked={values.isActive} onChange={handleChange} name="isActive" label={"Is active"} />
        </div>
      </div>

      <p className="form-title">Devices</p>

      <div className="form-block">
        <div className="form-item">
          <CheckBoxItem
            checked={values.devices.isNotebook}
            onChange={handleChange}
            name="devices.isNotebook"
            label={"Notebooks"}
          />
        </div>

        <div className="form-item">
          <CheckBoxItem
            checked={values.devices.isTablet}
            onChange={handleChange}
            name="devices.isTablet"
            label={"Tablets"}
          />
        </div>

        <div className="form-item">
          <CheckBoxItem
            checked={values.devices.isMobile}
            onChange={handleChange}
            name="devices.isMobile"
            label={"Mobiles"}
          />
        </div>

        <div className="form-item">
          <CheckBoxItem
            checked={values.devices.isOtherDevice}
            onChange={handleChange}
            name="devices.isOtherDevice"
            label={"Other devices"}
          />
        </div>
      </div>

      <p className="form-title">Opening hours</p>

      <div className="form-block">
        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.monday.start"
              label="Monday start"
              value={values.openingHours.monday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.monday.end"
              label="Monday end"
              value={values.openingHours.monday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.tuesday.start"
              label="Tuesday start"
              value={values.openingHours.tuesday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.tuesday.end"
              label="Tuesday end"
              value={values.openingHours.tuesday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.wednesday.start"
              label="Wednesday start"
              value={values.openingHours.wednesday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.wednesday.end"
              label="Wednesday end"
              value={values.openingHours.wednesday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.thursday.start"
              label="Thursday start"
              value={values.openingHours.thursday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.thursday.end"
              label="Thursday end"
              value={values.openingHours.thursday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.friday.start"
              label="Friday start"
              value={values.openingHours.friday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.friday.end"
              label="Friday end"
              value={values.openingHours.friday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.saturday.start"
              label="Saturday start"
              value={values.openingHours.saturday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.saturday.end"
              label="Saturday end"
              value={values.openingHours.saturday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.sunday.start"
              label="Sunday start"
              value={values.openingHours.sunday.start}
            />
          </div>
        </div>

        <div className="form-item">
          <div className="form-item-container">
            <TimePicker
              onChange={setOpeningHours}
              name="openingHours.sunday.end"
              label="Sunday end"
              value={values.openingHours.sunday.end}
            />
          </div>
        </div>

        <div className="form-item">
          <SimpleInput
            name="comment"
            label="Comment"
            value={values.comment}
            onBlur={handleBlur}
            onChange={handleChange}
            error={errors.comment}
            placeholder={"Comment"}
          />
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader type="submit" text="Save" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />

        <ButtonWithLoader
          text="Delete"
          onClick={() => handleDelete(values.id)}
          className="delete"
          isSubmitting={isSubmitting}
        />
      </div>
    </form>
  );
};

const ServiceCenterEditForm = withFormik({
  mapPropsToValues: e => {
    return {
      id: e.currentServiceCenter.id,
      title: e.currentServiceCenter.title,
      city: e.currentServiceCenter.city,
      street: e.currentServiceCenter.street,
      phone: e.currentServiceCenter.phone,
      latitude: e.currentServiceCenter.lat,
      longitude: e.currentServiceCenter.lng,
      isActive: e.currentServiceCenter.is_active,
      devices: {
        isNotebook: e.currentServiceCenter.devices.isNotebook,
        isMobile: e.currentServiceCenter.devices.isMobile,
        isOtherDevice: e.currentServiceCenter.devices.isOtherDevice,
        isTablet: e.currentServiceCenter.devices.isTablet,
      },
      openingHours: {
        ...(() => {
          let openingHoursObj = {};

          for (const [key, value] of Object.entries(e.currentServiceCenter.openingHours)) {
            openingHoursObj[key] = value.length ? value[0] : { start: null, end: null };
          }

          return openingHoursObj;
        })(),
      },
      comment: e.currentServiceCenter.comment,
      initialData: e.currentServiceCenter,
      serviceCentersData: e.serviceCentersData,
      handleClose: e.handleClose,
      updateServiceCenter: e.updateServiceCenter,
      deleteServiceCenter: e.deleteServiceCenter,
      serviceCentersFilters: e.serviceCentersFilters,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};
    if (!values.title) {
      errors.title = "Field is Required";
    }

    if (!values.city) {
      errors.city = "Field is Required";
    }

    if (!values.street) {
      errors.street = "Field is Required";
    }

    if (!values.latitude) {
      errors.latitude = "Field is Required";
    }

    if (values.latitude > 180 || values.latitude < -180) {
      errors.latitude = "Latitude value should be between 180 and -180";
    }

    if (!values.longitude) {
      errors.longitude = "Field is Required";
    }

    if (values.longitude > 90 || values.longitude < -90) {
      errors.longitude = "Latitude value should be between 90 and -90";
    }

    if (values.phone && !validationService.isPhoneValid(values.phone)) {
      errors.phone = "Phone doesn't valid";
    }

    if (!values.phone) {
      errors.phone = "Field is Required";
    }

    if (values.comment.length >= 100) {
      errors.comment = "Should be less then 100 characters";
    }

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    const {
      id,
      title,
      city,
      street,
      phone,
      latitude,
      longitude,
      isActive,
      devices,
      openingHours,
      comment,
      serviceCentersData,
      updateServiceCenter,
      handleClose,
      dispatch,
    } = values;

    const data = {
      id,
      title,
      city,
      street,
      phone,
      latitude: Number(latitude),
      longitude: Number(longitude),
      active: isActive,
      repair_mobile: devices.isMobile,
      repair_notebook: devices.isNotebook,
      repair_tablet: devices.isTablet,
      repair_other: devices.isOtherDevice,
      working_hours: {
        ...(() => {
          let openingHoursObj = {};

          for (const [key, value] of Object.entries(openingHours)) {
            openingHoursObj[key] = value.start && value.end ? [{ start: value.start, end: value.end }] : [];
          }

          return openingHoursObj;
        })(),
      },
      comment,
    };

    updateServiceCenter(data, serviceCentersData, handleClose, setSubmitting, dispatch);
  },
})(Form);

ServiceCenterEditForm.propTypes = {
  currentServiceCenter: PropTypes.object.isRequired,
  serviceCentersData: PropTypes.array.isRequired,
  updateServiceCenter: PropTypes.func.isRequired,
  deleteServiceCenter: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ServiceCenterEditForm;
