import React from "react";
import PropTypes from "prop-types";

// Components
import InfoBlockArray from "./components/InfoBlockArray";
import InfoBlockObject from "./components/InfoBlockObject";

export const InfoBlockComplex = ({ label, textData }) => {
  if (!textData) return null;

  return (
    <div className="info-block">
      <p className="info-block-label">{label}</p>
      <div className="info-block-txt">
        {Array.isArray(textData) ? <InfoBlockArray textData={textData} /> : <InfoBlockObject textData={textData} />}
      </div>
    </div>
  );
};

InfoBlockComplex.propTypes = {
  label: PropTypes.string.isRequired,
  // textData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};
