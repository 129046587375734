import { TransportService } from "./transportService";
const transportService = new TransportService();

const CompaniesApi = {
  getCompanies: (page = 1) => transportService.requestData("GetCompanies", null, { page: page }),
  addCompany: formData => transportService.requestData("AddCompany", formData),
  updateCompany: formData => transportService.requestData("UpdateCompany", formData, null, formData.id),
  deleteCompany: companyId => transportService.requestData("DeleteCompany", null, null, companyId),
};

export default CompaniesApi;
