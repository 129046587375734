import React from "react";
import { useDispatch } from "react-redux";

// Actions
import { userLogout } from "../store/actions/loginActions";

// Material UI
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "relative",
  },
}));

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <AppBar position="static" className={`"header", ${classes.appBar}`}>
      <Toolbar className="header-content">
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Button color="inherit" onClick={() => userLogout(dispatch)}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
