import React from "react";
import Swal from "sweetalert2";

// Components
import { CheckBoxItem } from "../../../components/formElements";

// Actions
import { activateUser } from "../../../store/actions/usersActions";

export default function UserEditAdditional({ currentUser, usersDataObj, dispatch }) {
  const handleActivateUser = () => {
    Swal.fire({
      title: "Do you want to activate this User?",
      showCancelButton: true,
      confirmButtonText: `Activate`,
      confirmButtonColor: "#d14529",
    }).then(result => result.isConfirmed && activateUser(currentUser, usersDataObj, dispatch));
  };

  return (
    <form className="form">
      <p className="form-title">Additional settings</p>
      <p className="form-subtitle">These settings would be saved automatically by a change</p>

      <div className="form-block">
        <div className="form-item">
          <CheckBoxItem
            checked={currentUser.is_active}
            onChange={handleActivateUser}
            name="isActive"
            label={currentUser.is_active ? "User activated" : "Activate user"}
            disabled={currentUser.is_active}
          />
        </div>
      </div>
    </form>
  );
}
