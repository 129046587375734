import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

// Material UI
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";

// Components
import Loader from "../../../components/Loader";
import TableFilters from "./TableFilters";

// Utils
import extractValueFromNestedObject from "../../../utils/extractValueFromNestedObject";

const TableComponent = ({ columns, generalData, onRowClick, dataIsLoading, isCanAddNewItem }) => {
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [page, setPage] = useState(1);

  const paginationSelectValues = [10, 25, 50, 100];

  const paginateTableData = (generalData, rowsPerPage, page) => {
    return generalData.slice((page - 1) * rowsPerPage, page * rowsPerPage);
  };

  const handleChangePage = (event, newPage) => setPage(newPage + 1);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);

    // Reset pagination counter
    paginateTableData(generalData, rowsPerPage, page) && setPage(1);
  };

  const handleRowClick = row => {
    if (typeof onRowClick === "function") {
      onRowClick(row);
    }
  };

  const formatTableCellValue = (column, row) => {
    const value = extractValueFromNestedObject(column.key, row);

    let outputCellValue;

    if (column.isBool) {
      value === false || value === null ? (outputCellValue = <CloseIcon />) : (outputCellValue = <CheckIcon />);
    } else if (column.isTime) {
      outputCellValue = value.slice(0, -7);
    } else {
      outputCellValue = value;
    }

    return outputCellValue;
  };

  const handleChangeFiltersVisibility = () => setIsFiltersVisible(!isFiltersVisible);

  return (
    <>
      {dataIsLoading ? (
        <Loader isSection={true} />
      ) : (
        <div className="table-container">
          {isCanAddNewItem && (
            <div className="table-top">
              <Button
                variant="contained"
                color="primary"
                startIcon={<FilterListIcon />}
                onClick={handleChangeFiltersVisibility}
              >
                Filters
              </Button>

              <Button
                component={Link}
                to={`${history.location.pathname}/add`}
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                Add New
              </Button>
            </div>
          )}

          <TableFilters isFiltersVisible={isFiltersVisible} />

          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell key={index}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginateTableData(generalData, rowsPerPage, page).map(row => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={() => handleRowClick(row)}>
                        {columns.map((column, index) => {
                          const cellValue = formatTableCellValue(column, row);

                          return <TableCell key={index}>{cellValue}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={paginationSelectValues}
              component="div"
              count={generalData.length}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  generalData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  dataIsLoading: PropTypes.bool.isRequired,
  isCanAddNewItem: PropTypes.bool,
};

export default TableComponent;
