import React from "react";
import { SimpleInput } from "./formElements";
import SearchIcon from "@material-ui/icons/Search";

export default function SearchBlock({ getSearch, fetchData, dispatch }) {
  const handleSearchByName = event => {
    event.target.value.length > 2 && getSearch(`name/${event.target.value}`, dispatch);
    event.target.value.length === 0 && fetchData(1, dispatch);
  };
  const handleSearchByEmail = event => {
    event.target.value.length > 2 && getSearch(`email/${event.target.value}`, dispatch);
    event.target.value.length === 0 && fetchData(1, dispatch);
  };

  return (
    <div className="form-block">
      <div className="form-item">
        <div className="__with-icon">
          <SearchIcon />
          <SimpleInput label="Search by name" onChange={handleSearchByName} />
        </div>
      </div>

      <div className="form-item">
        <div className="__with-icon">
          <SearchIcon />
          <SimpleInput label="Search by email" onChange={handleSearchByEmail} />
        </div>
      </div>
    </div>
  );
}
