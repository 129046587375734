export const APP_LOADING_FINISHED = "APP_LOADING_FINISHED";

export const USER_LOGIN_STARTED = "USER_LOGIN_STARTED";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const FETCHING_ROLES_STARTED = "FETCHING_ROLES_STARTED";
export const FETCHING_ROLES_SUCCESS = "FETCHING_ROLES_SUCCESS";
export const FETCHING_ROLES_FAIL = "FETCHING_ROLES_FAIL";

export const FETCHING_COMPANIES_STARTED = "FETCHING_COMPANIES_STARTED";
export const FETCHING_COMPANIES_SUCCESS = "FETCHING_COMPANIES_SUCCESS";
export const FETCHING_COMPANIES_FAIL = "FETCHING_COMPANIES_FAIL";

export const FETCHING_USERS_STARTED = "FETCHING_USERS_STARTED";
export const FETCHING_USERS_SUCCESS = "FETCHING_USERS_SUCCESS";
export const FETCHING_USERS_FAIL = "FETCHING_USERS_FAIL";
export const USERS_UPDATED = "USERS_UPDATED";
export const FETCHING_CURRENT_USER_STARTED = "FETCHING_CURRENT_USER_STARTED";
export const FETCHING_CURRENT_USER_SUCCESS = "FETCHING_CURRENT_USER_SUCCESS";
export const FETCHING_CURRENT_USER_FAIL = "FETCHING_CURRENT_USER_FAIL";
export const CURRENT_USER_UPDATED = "CURRENT_USER_UPDATED";

export const FETCHING_AUTH_LOGS_STARTED = "FETCHING_AUTH_LOGS_STARTED";
export const FETCHING_AUTH_LOGS_SUCCESS = "FETCHING_AUTH_LOGS_SUCCESS";
export const FETCHING_AUTH_LOGS_FAIL = "FETCHING_AUTH_LOGS_FAIL";

export const FETCHING_SERVICE_CENTERS_STARTED = "FETCHING_SERVICE_CENTERS_STARTED";
export const FETCHING_SERVICE_CENTERS_SUCCESS = "FETCHING_SERVICE_CENTERS_SUCCESS";
export const FETCHING_SERVICE_CENTERS_FAIL = "FETCHING_SERVICE_CENTERS_FAIL";
export const SERVICE_CENTERS_UPDATED = "SERVICE_CENTERS_UPDATED";
export const FETCHING_CURRENT_SERVICE_CENTER_STARTED = "FETCHING_CURRENT_SERVICE_CENTER_STARTED";
export const FETCHING_CURRENT_SERVICE_CENTER_SUCCESS = "FETCHING_CURRENT_SERVICE_CENTER_SUCCESS";
export const FETCHING_CURRENT_SERVICE_CENTER_FAIL = "FETCHING_CURRENT_SERVICE_CENTER_FAIL";
export const CURRENT_SERVICE_CENTER_UPDATED = "CURRENT_SERVICE_CENTER_UPDATED";
export const SERVICE_CENTERS_FILTERS_CHANGED = "SERVICE_CENTERS_FILTERS_CHANGED";

export const SHOW_SNACK_BAR_NOTIFIER = "SHOW_SNACK_BAR_NOTIFIER";
export const HIDE_SNACK_BAR_NOTIFIER = "HIDE_SNACK_BAR_NOTIFIER";
