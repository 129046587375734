import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import RoleAddForm from "./components/RoleAddForm";

// Actions
import { addRole } from "../../store/actions/rolesActions";

// Services
import { ValidationService } from "../../js/validationService";
const validationService = new ValidationService();

export default function RoleAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const rolesDataObj = useSelector(state => state.roles.rolesDataObj);

  const handleClose = () => {
    const previousLocation = validationService.getPreviousHistoryLocationPathname(history.location.pathname)[0];

    history.push(`/${previousLocation}`);
  };

  return <RoleAddForm rolesDataObj={rolesDataObj} handleClose={handleClose} addRole={addRole} dispatch={dispatch} />;
}
