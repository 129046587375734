import * as types from "../actionTypes";

const initialState = {
  isAppLoading: true,
  isUserAuthorized: false,
  userData: {},
  isLoginSubmitting: false,
  isLoginError: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.APP_LOADING_FINISHED:
      return {
        ...state,
        isAppLoading: action.payload,
      };
    case types.USER_LOGIN_STARTED:
      return {
        ...state,
        isLoginSubmitting: true,
        isLoginError: false,
      };
    case types.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isUserAuthorized: action.isUserAuthorized,
        userData: action.userData,
        isLoginSubmitting: false,
      };
    case types.USER_LOGIN_FAIL:
      return {
        ...state,
        isLoginError: action.payload,
        isLoginSubmitting: false,
      };
    case types.USER_LOGOUT:
      return {
        ...state,
        isUserAuthorized: false,
        userData: {},
      };
    default:
      return state;
  }
};

export default loginReducer;
