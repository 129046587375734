import React from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@material-ui/core/Button";

// Components
import { InfoBlockSimple } from "../../../components/InfoBlocks/InfoBlockSimple";
import { InfoBlockComplex } from "../../../components/InfoBlocks/InfoBlockComplex";

const UserInfo = ({ currentUser, handleClose }) => {
  return (
    <div className="info">
      <div className="info-content">
        <InfoBlockSimple label={"Uuid"} textData={currentUser.uuid} />
        <InfoBlockSimple label={"First name"} textData={currentUser.first_name} />
        <InfoBlockSimple label={"Last name"} textData={currentUser.last_name} />
        <InfoBlockSimple label={"Patronymic"} textData={currentUser.patronymic} />
        <InfoBlockSimple label={"Email"} textData={currentUser.email} />
        <InfoBlockSimple label={"Phone"} textData={currentUser.phone} />
        <InfoBlockSimple label={"Is active"} textData={currentUser.is_active} />
        <InfoBlockSimple label={"Handedness"} textData={currentUser.handedness} />
        <InfoBlockSimple label={"Stock view"} textData={currentUser.is_user_can_see_distributors} />

        <InfoBlockComplex label={"Birthday"} textData={currentUser.birthday} />
        <InfoBlockComplex label={"Addresses"} textData={currentUser.addresses} />

        <InfoBlockSimple label={"Is extended filter"} textData={currentUser.is_extended_filter} />
        <InfoBlockSimple label={"Lang"} textData={currentUser.lang} />
        <InfoBlockSimple label={"Position"} textData={currentUser.position} />
        <InfoBlockSimple label={"Requested company"} textData={currentUser.requested_company} />

        <InfoBlockComplex label={"Role"} textData={currentUser.role} />
        <InfoBlockComplex label={"Companies"} textData={currentUser.companies} />
        <InfoBlockComplex label={"Service notifications"} textData={currentUser.service_notifications} />
        <InfoBlockComplex label={"Subscriber info list"} textData={currentUser.subscriber_info.list} />
        <InfoBlockComplex label={"Subscriber info types"} textData={currentUser.subscriber_info.types} />
        <InfoBlockComplex label={"Social accounts"} textData={currentUser.social_accounts} />
        <InfoBlockComplex label={"counters"} textData={currentUser.counters} />
      </div>

      <Button type="button" variant="contained" color="primary" onClick={handleClose}>
        Close
      </Button>
    </div>
  );
};

UserInfo.propTypes = {
  currentUser: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserInfo;
