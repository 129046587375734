import React from "react";
import { useDispatch, useSelector } from "react-redux";

// Material UI
import Button from "@material-ui/core/Button";

// Components
import { CheckBoxItem } from "../../../components/formElements";

// Actions
import { fetchServiceCenters, changeServiceCentersFilters } from "../../../store/actions/serviceCentersActions";

export default function TableFilters({ isFiltersVisible }) {
  const dispatch = useDispatch();
  const serviceCentersFilters = useSelector(state => state.serviceCenters.serviceCentersFilters);

  const handleFilterChange = e =>
    changeServiceCentersFilters({ [e.target.name]: !serviceCentersFilters[e.target.name] }, dispatch);

  const applyFilters = () => fetchServiceCenters(serviceCentersFilters, dispatch);

  return (
    <div className={`table-filters form-block ${isFiltersVisible ? "__visible" : ""}`}>
      <div className="table-filters-container">
        <CheckBoxItem
          name="is_active"
          label="Active"
          checked={serviceCentersFilters.is_active}
          onChange={handleFilterChange}
        />
        <CheckBoxItem
          name="is_deleted"
          label="Deleted"
          checked={serviceCentersFilters.is_deleted}
          onChange={handleFilterChange}
        />
      </div>

      <Button variant="contained" color="primary" onClick={applyFilters}>
        Apply filters
      </Button>
    </div>
  );
}
