import React from "react";
import PropTypes from "prop-types";

// Components
import IconicBool from "./IconicBool";

const InfoBlockArray = ({ textData }) => {
  return textData.map((textDataItem, index) => {
    return (
      <p className="info-block-txt-row" key={index}>
        {Object.keys(textDataItem).map((key, index) => {
          return (
            <span className="info-block-txt-item" key={index}>
              <span className="info-block-txt-item-label">{key.replace("_", " ")}: </span>
              <span className="info-block-txt-item-value">
                <IconicBool>{textDataItem[key]}</IconicBool>
              </span>
            </span>
          );
        })}
      </p>
    );
  });
};

InfoBlockArray.propTypes = {
  textData: PropTypes.array.isRequired,
};

export default InfoBlockArray;
