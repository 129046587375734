import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import TableComponent from "../../components/TableComponent";

// Actions
import { fetchUsers } from "../../store/actions/usersActions";

const tableColumns = [
  { label: "№", key: "id" },
  { label: "First name", key: "first_name" },
  { label: "Last name", key: "last_name" },
  { label: "Email", key: "email" },
  { label: "Role", key: "role.name" },
  { label: "Stock view", key: "is_user_can_see_distributors", isBool: true },
  { label: "Is active", key: "is_active", isBool: true },
];

export default function UsersList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const usersDataObj = useSelector(state => state.users.usersDataObj);
  const usersDataLoading = useSelector(state => state.users.usersDataLoading);

  useEffect(() => !Object.keys(usersDataObj).length && fetchUsers(1, dispatch), []);
  const showItemByClick = user => history.push(`${history.location.pathname}/${user.uuid}`);
  const getDataForNewPage = newPage => fetchUsers(newPage + 1, dispatch);

  return (
    <TableComponent
      dataIsLoading={usersDataLoading}
      columns={tableColumns}
      generalData={usersDataObj}
      onRowClick={showItemByClick}
      onPageChange={getDataForNewPage}
    />
  );
}
