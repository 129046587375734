import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import TableComponent from "../../components/TableComponent";

// Actions
import { getCompaniesData } from "../../store/actions/companiesActions";

const tableColumns = [
  { label: "ID", key: "id" },
  { label: "Name", key: "name" },
];

export default function CompaniesList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const companiesDataObj = useSelector(state => state.companies.companiesDataObj);
  const companiesDataLoading = useSelector(state => state.companies.companiesDataLoading);

  useEffect(() => !Object.keys(companiesDataObj).length && getCompaniesData(1, dispatch), []);

  const showItemByClick = company => history.push(`${history.location.pathname}/${company.id}`);

  const getDataForNewPage = newPage => getCompaniesData(newPage + 1, dispatch);

  return (
    <TableComponent
      dataIsLoading={companiesDataLoading}
      columns={tableColumns}
      generalData={companiesDataObj}
      onRowClick={showItemByClick}
      isCanAddNewItem
      onPageChange={getDataForNewPage}
    />
  );
}
