import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import Swal from "sweetalert2";

// Components
import { SimpleInput, ButtonWithLoader } from "../../../components/formElements";
import BusinessIcon from "@material-ui/icons/Business";

const Form = props => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    handleClose,
    companiesDataObj,
    deleteCompany,
    dispatch,
  } = props;

  const handleDeleteCompany = companyId => {
    Swal.fire({
      title: "Do you want to delete this Company?",
      showCancelButton: true,
      confirmButtonText: `Delete`,
      confirmButtonColor: "#d14529",
    }).then(
      result => result.isConfirmed && deleteCompany(companyId, companiesDataObj, handleClose, setSubmitting, dispatch),
    );
  };

  return (
    <form onSubmit={handleSubmit} className="form">
      <div className="form-block">
        <div className="form-item">
          <SimpleInput name="id" label="Id" value={values.id} disabled />
        </div>
        <div className="form-item">
          <div className="__with-icon">
            <BusinessIcon />
            <SimpleInput
              name="company"
              label="Company"
              value={values.company}
              onChange={handleChange}
              error={errors.company}
            />
          </div>
        </div>
      </div>

      <div className="form-buttons">
        <ButtonWithLoader type="submit" text="Save" className="submit" isSubmitting={isSubmitting} />

        <ButtonWithLoader text="Cancel" onClick={handleClose} isSubmitting={isSubmitting} />

        <ButtonWithLoader
          text="Delete"
          onClick={() => handleDeleteCompany(values.id)}
          className="delete"
          isSubmitting={isSubmitting}
        />
      </div>
    </form>
  );
};

const CompanyEditForm = withFormik({
  mapPropsToValues: e => {
    return {
      id: e.currentCompany.id,
      company: e.currentCompany.name,
      initialCompany: e.currentCompany.name,
      companiesDataObj: e.companiesDataObj,
      handleClose: e.handleClose,
      updateCompany: e.updateCompany,
      deleteCompany: e.deleteCompany,
      dispatch: e.dispatch,
    };
  },

  // Custom sync validation
  validate: values => {
    let errors = {};
    !values.company && (errors.company = "Field is Required");
    values.company === values.initialCompany && (errors.company = "The name has already been taken");

    return errors;
  },

  handleSubmit: (values, { setSubmitting }) => {
    // Data constructor
    const data = { id: values.id, name: values.company };

    const UpdateCurrentCompanyInfoInCompaniesList = () => {
      const currentCompanyIndex = values.companiesDataObj.data.findIndex(company => company.id === values.id);

      values.companiesDataObj.data[currentCompanyIndex] = data;
    };

    UpdateCurrentCompanyInfoInCompaniesList();

    values.updateCompany(data, values.companiesDataObj, values.handleClose, setSubmitting, values.dispatch);
  },
})(Form);

CompanyEditForm.propTypes = {
  currentCompany: PropTypes.object.isRequired,
  companiesDataObj: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
};

export default CompanyEditForm;
