import React from "react";
import PropTypes from "prop-types";

const Loader = ({ isSection = false }) => {
  return (
    <div className={`loader ${isSection ? "__section" : ""}`}>
      <ul className="loader-elements">
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
};

Loader.propTypes = {
  isSection: PropTypes.bool,
};

export default Loader;
