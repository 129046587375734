import * as types from "../actionTypes";
import RolesApi from "../../api/rolesApi";
import { openNotifier } from "./notificationActions";

export const fetchRoles = (page, dispatch) => {
  dispatch({ type: types.FETCHING_ROLES_STARTED });

  RolesApi.getRoles(page)
    .then(response => dispatch({ type: types.FETCHING_ROLES_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: types.FETCHING_ROLES_FAIL }));
};

export const addRole = (formData, rolesDataObj, closeForm, setSubmitting, dispatch) => {
  RolesApi.addRole(formData)
    .then(response => {
      rolesDataObj.data.push(response.data);

      dispatch({ type: types.FETCHING_ROLES_SUCCESS, payload: rolesDataObj });

      closeForm();
      openNotifier("success", "Role has been added!", dispatch);
    })
    .catch(error => {
      setSubmitting();
      openNotifier("error", `Role wasn't added. ${error}`, dispatch);
    });
};

export const updateRole = (formData, updatedRolesDataObj, closeForm, setSubmitting, dispatch) => {
  RolesApi.updateRole(formData)
    .then(() => {
      dispatch({ type: types.FETCHING_ROLES_SUCCESS, payload: updatedRolesDataObj });

      closeForm();
      openNotifier("success", "Role has been updated!", dispatch);
    })
    .catch(error => {
      setSubmitting();
      openNotifier("error", `Role wasn't updated. ${error}`, dispatch);
    });
};

export const deleteRole = (roleId, rolesDataObj, closeForm, setSubmitting, dispatch) => {
  RolesApi.deleteRole(roleId)
    .then(() => {
      fetchRoles(rolesDataObj.current_page, dispatch);
      closeForm();
      openNotifier("warning", "Role has been deleted!", dispatch);
    })
    .catch(error => {
      setSubmitting();
      openNotifier("error", `Role wasn't deleted. ${error}`, dispatch);
    });
};
