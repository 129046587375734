import React from "react";
import PropTypes from "prop-types";
import IconicBool from "./IconicBool";

const InfoBlockObject = ({ textData }) => {
  return Object.keys(textData).map((key, index) => {
    return (
      <span className="info-block-txt-item" key={index}>
        <span className="info-block-txt-item-label">{key.replace("_", " ")}: </span>
        <span className="info-block-txt-item-value">
          <IconicBool>{textData[key]}</IconicBool>
        </span>
      </span>
    );
  });
};

InfoBlockObject.propTypes = {
  textData: PropTypes.object.isRequired,
};

export default InfoBlockObject;
