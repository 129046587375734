import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Components
import Loader from "../../components/Loader";
import TabPanel from "../../components/TabPanel";
import ServiceCenterInfo from "./components/ServiceCenterInfo";
import ServiceCenterEdit from "./ServiceCenterEdit";

// Actions
import { fetchServiceCenters } from "../../store/actions/serviceCentersActions";

// Services
import { ValidationService } from "../../js/validationService";
const validationService = new ValidationService();

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ServiceCenterItem() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const serviceCentersData = useSelector(state => state.serviceCenters.serviceCentersData);
  const [value, setValue] = useState(0);
  const [currentServiceCenterData, setCurrentServiceCenterData] = useState({});
  const [serviceCenterItemIsLoading, setServiceCenterItemIsLoading] = useState(true);
  const [serviceCenterItemNotFound, setServiceCenterItemNotFound] = useState(false);

  const handleChange = (event, newValue) => setValue(newValue);

  const params = useParams();
  const serviceCenterId = params.id;

  useEffect(() => {
    if (serviceCentersData.length) {
      const currentServiceCenter = serviceCentersData.filter(serviceCenter => serviceCenter.id === serviceCenterId);

      currentServiceCenter.length > 0
        ? setCurrentServiceCenterData(currentServiceCenter[0])
        : setServiceCenterItemNotFound(true);

      setServiceCenterItemIsLoading(false);
    } else {
      fetchServiceCenters({}, dispatch);
    }
  }, [serviceCenterId, serviceCentersData]);

  const handleClose = () => {
    const previousLocation = validationService.getPreviousHistoryLocationPathname(history.location.pathname)[0];

    history.push(`/${previousLocation}`);
  };

  return (
    <>
      {serviceCenterItemIsLoading ? (
        <Loader isSection={true} />
      ) : serviceCenterItemNotFound ? (
        "The current service center didn't found"
      ) : (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="auto tabs"
            >
              <Tab label="Service center info" />
              <Tab label="Edit Service center" disabled={currentServiceCenterData.deleted_at !== null} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <ServiceCenterInfo currentServiceCenter={currentServiceCenterData} handleClose={handleClose} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ServiceCenterEdit
              currentServiceCenter={currentServiceCenterData}
              serviceCentersData={serviceCentersData}
              handleClose={handleClose}
            />
          </TabPanel>
        </div>
      )}
    </>
  );
}
