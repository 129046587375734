import { createTheme } from "@material-ui/core/styles/index";

const theme = createTheme({
  palette: {
    primary: { main: "#3f51b5" },
    secondary: { main: "#000" },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"NeueHaasUnicaW1G-Regular", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid rgba(204,204,204, 1)",
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0px, -3.5px) scale(0.75)",
      },
    },
    MuiButton: {
      root: {
        fontSize: "inherit",
        textTransform: "none",
        fontFamily: '"NeueHaasUnicaW1G-Regular", "Helvetica", "Arial", sans-serif',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "16px",
      },
    },
    MuiList: {
      root: {
        paddingBottom: 0,
        paddingTop: 0,
      },
      padding: {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    MuiPopover: {
      paper: {
        // maxWidth: "320px",
        top: "80px",

        // marginLeft: "10px",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#f00",
      },
    },
    MuiPickersDay: {
      day: {
        color: "black",
      },
      daySelected: {
        backgroundColor: "#f00",
      },
      current: {
        color: "black",
      },
    },
    MuiPickersModal: {
      // dialogAction: {
      //   color: "black",
      //   fontSize: "18px",
      // }
    },
  },
});

export default theme;
