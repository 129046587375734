import { TransportService } from "./transportService";
const transportService = new TransportService();

const UsersApi = {
  getUsers: (page = 1) => transportService.requestData("GetUsers", null, { page: page }),
  getUser: userUuid => transportService.requestData("GetUsers", null, null, userUuid),
  searchUser: query => transportService.requestData("SearchUser", null, null, query),
  updateUser: formData => transportService.requestData("UpdateUser", formData, null, formData.uuid),
  deleteUser: userId => transportService.requestData("DeleteUser", null, null, userId),
  activateUser: userId => transportService.requestData("ActivateUser", null, null, `${userId}/activate`),
};

export default UsersApi;
