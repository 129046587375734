import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import ServiceCenterAddForm from "./components/ServiceCenterAddForm";

// Actions
import { addServiceCenter } from "../../store/actions/serviceCentersActions";

// Services
import { ValidationService } from "../../js/validationService";
const validationService = new ValidationService();

export default function ServiceCenterAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const serviceCentersData = useSelector(state => state.serviceCenters.serviceCentersData);

  const handleClose = () => {
    const previousLocation = validationService.getPreviousHistoryLocationPathname(history.location.pathname)[0];

    history.push(`/${previousLocation}`);
  };

  return (
    <ServiceCenterAddForm
      serviceCentersData={serviceCentersData}
      handleClose={handleClose}
      addServiceCenter={addServiceCenter}
      dispatch={dispatch}
    />
  );
}
