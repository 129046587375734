import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import TableComponent from "../../components/TableComponent";

// Actions
import { fetchRoles } from "../../store/actions/rolesActions";

const tableColumns = [
  { label: "ID", key: "id" },
  { label: "Name", key: "name" },
  { label: "Slug", key: "slug" },
];

export default function RolesList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const rolesDataObj = useSelector(state => state.roles.rolesDataObj);
  const rolesDataLoading = useSelector(state => state.roles.rolesDataLoading);

  useEffect(() => !Object.keys(rolesDataObj).length && fetchRoles(1, dispatch), []);

  const showItemByClick = role => history.push(`${history.location.pathname}/${role.id}`);

  const getDataForNewPage = newPage => fetchRoles(newPage + 1, dispatch);

  return (
    <TableComponent
      dataIsLoading={rolesDataLoading}
      columns={tableColumns}
      generalData={rolesDataObj}
      onRowClick={showItemByClick}
      isCanAddNewItem
      onPageChange={getDataForNewPage}
    />
  );
}
